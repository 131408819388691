import React, { useLayoutEffect, useState, useCallback, useEffect, useRef } from 'react';
import {parseXmlOfxToJson} from '../../utils/ofxParser';

import {
    Button,
    Tab,
    Tabs,
    Spinner,
    Modal,
    Badge,
    Tooltip,
    ButtonToolbar,
    OverlayTrigger
} from "react-bootstrap";

import {
    Paper,
    Table,
    TableRow,
    MenuItem,
    Checkbox,
    TableHead,
    TableBody,
    TableCell,
    TextField,
    TablePagination,
    FormControlLabel,
    FormGroup,
    FormControl,
    FormLabel,
    TableFooter,
    makeStyles,
    InputAdornment,
    IconButton,
} from '@material-ui/core';

import '../../style.css';

import api from '../../services/Api';
import { SubCategory } from '../../types/Dre';
import { paymentOptions } from '../../utils/paymentOptions';
import { useSelector } from 'react-redux';
import ApiResourceSelect from '../../components/ApiResourceSelect';
import CustomerService from '../../services/CustomerService';
import BankAccountService from '../../services/BankAccountService';
import DreCategoryService from '../../services/DreCategoryService';
import { CenterCost } from '../../types/CenterCost';
import CenterCostService from '../../services/CenterCostService';
import { formatToFloat } from '../../utils/formatCurrency';
import { BillsToReceive } from '../../types/BillsToReceive';
import { BillsToPay } from '../../types/BillsToPay';
import { useHistory } from 'react-router';
import ModalSuccess from '../../components/ModalSuccess';
import { Link } from 'react-router-dom';
import { ValidationBadge } from '../../components/ValidationBadge';
import { ApiResourceSelectAddButton } from '../../components/ApiResourceSelectAddButton';
import { NewCustomerModal } from '../../components/Customer/NewCustomerModal';
import useNewCustomerForm from '../../hooks/newCustomerForm';
import { Customer as CustomerType } from '../../types/Customer';


const useStyles = makeStyles((theme) => ({
    container: {
       display: 'flex',
       flexWrap: 'wrap',
    },
    textField: {
       marginLeft: theme.spacing(1),
       marginRight: theme.spacing(1),
    },
    dense: {
       marginTop: theme.spacing(2),
    },
    menu: {
       width: 200,
    },
    error: {
       "& .Mui-error": {
          color: "#f64e60 !important"
       },
       "& .MuiFormHelperText-root": {
          color: "#f64e60 !important"
       },
       "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
          borderColor: "#f64e60 !important"
       }
    }
 }));


type searchData = {
    checked: boolean;
    dueDate: string;
    name: string;
    amount: number;
    payment: string;
    supplier?: number;
    customer?: number;
}

type extract = {
    checked: boolean,
    type: string,
    date: string,
    value: string,
    title: string,
    category?: string,
    dreSubCategoryId: number,
    centerCost: string,
    conciliation: conciliation,
    concilied: boolean;
    conciliationInProgress: boolean;
    isEdittingConcilied?: boolean;
}

type conciliation = {
    id: number,
    type: string,
    date: string,
    value: string,
    title: string,
    category?: string,
    dreSubCategoryId: number,
    centerCost: string,
    payment: string,
    supplierOrCustomer: number,
    valueApproximate: boolean,
    validationErrors: {
        title: boolean,
        dreSubCategoryId: boolean,
        payment: boolean,
        supplierOrCustomer: boolean,
    },
    status?: string,
    dueDate?:string,
}

type ConciliationBankExtract = {
    type: string;
    date: string;
    value: any;
    title: any;
}

type BankAccount = {
    id: number;
    name: string;
    nameBank: string;
    numberBank: string;
    account: string;
    accountDigit: string;
};

type Customer = {
    id: number;
    name: string;
    corporateName: string;
    typeRegister: "supplier" | "customer";
}

export function NewBankReconciliation() {
    const { user } = useSelector((state: any) => state.auth);

    const classes = useStyles();

    const { location: { pathname }, push: pushHistory } = useHistory();

    const inputFileRef = useRef<HTMLInputElement>(null);

    const extractDefaultValue = {
        checked: false,
        type: "",
        date: "",
        value: "",
        title: "",
        category: "",
        dreSubCategoryId: 0,
        centerCost: "",
        conciliation: {
            id: 0,
            type: "",
            date: "",
            value: "",
            title: "",
            category: "",
            dreSubCategoryId: 0,
            centerCost: "",
            payment: "",
            supplierOrCustomer: 0,
            valueApproximate: false,
            validationErrors: {
                title: false,
                dreSubCategoryId: false,
                payment: false,
                supplierOrCustomer: false,
            }
        },
        concilied: false,
        conciliationInProgress: false,
        isEdittingConcilied: false,
    };
    
    // States
    const [uploadedFileList, setUploadedFileList]           = useState<FileList | null | undefined>(null);
    const [isReadingFile, setIsReadingFile]                 = useState(false);
    const [isDraggingFileOver, setIsDraggingFileOver]       = useState(false);
    const [showSuccessModal, setShowSuccessModal]           = useState(false);
    const [showExtract, setShowExtract]                     = useState(false);
    const [pages, setPages]                                 = useState(0);
    const [rowsPerPage, setRowsPerPage]                     = useState(5);
    const [initialDateSearch, setInitialDateSearch]         = useState("");
    const [finalDateSearch, setFinalDateSearch]             = useState("");
    const [searchText, setSearchText]                       = useState("");
    const [searchData, setSearchData]                       = useState<searchData[]>([]);
    const [extractSelected, setExtractSelected]             = useState<extract>();
    const [indexExtractSelected, setIndexExtractSelected]   = useState("");
    const [accountSelected, setAccountSelected]             = useState<BankAccount>();
    const [accountIdSelected, setAccountIdSelected]         = useState(0);
    const [extractTypeFilter, setExtractTypeFilter]         = useState("all");
    const [selectAll, setSelectAll]                         = useState(false);
    const [nextBatch, setNextBatch]                         = useState(0);
    const [bankStatement, setBankStatement]                 = useState<{extract: extract[]}>({
        extract: [extractDefaultValue]
    });
    const [indexBankStatementToConciliate, setIndexBankStatementToConciliate] = useState(0);
    const [loading, setLoading] = useState(0);
    
    // Modal
    const [showModalConfirm, setShowModalConfirm]   = useState(false);
    const [showModalConfirmSingle, setShowModalConfirmSingle]   = useState(false);
    const [showModalError, setShowModalError]       = useState(false);
    const [msgError, setMsgError]                   = useState("");
    const [showModalSearchExtractSystem, setShowModalSearchExtractSystem] = useState(false);
    const [titleModalSearch, setTitleModalSearch]   = useState("");

    // Modal New Customer
    const [indexExtractRowAddingCustomer, setIndexExtractRowAddingCustomer] = useState(0);
    const [typeCustomerBeingAdded, setTypeCustomerBeingAdded] = useState<'customer' | 'supplier'>('customer');
    const {
        showModalNewCustomer, setShowModalNewCustomer,
        newCustomerDefaultData, setNewCustomerDefaultData,
    } = useNewCustomerForm();

    const handleCreateCustomer = (createdCustomer: CustomerType) => {
        var bank = bankStatement.extract;
        bank[indexExtractRowAddingCustomer].conciliation.supplierOrCustomer = createdCustomer.id;
        setBankStatement({ extract: bank });
    };
    
    const handleClickAddCustomer = (typedText: string, typeRegister: 'supplier' | 'customer', indexExtractRow: number) => {
        setNewCustomerDefaultData({
            name: typedText,
            typeRegister,
        });
        setIndexExtractRowAddingCustomer(indexExtractRow);
        setTypeCustomerBeingAdded(typeRegister);
        setShowModalNewCustomer(true);
    };

    // Format 
    const formatDecimal = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
        minimumFractionDigits: 2
    });

    const areSystemExtractFieldsDisabled = 

    useEffect(() => {
        async function getLastBatchNumber() {
            const response = await api.get('/bankReconciliation/lastBatch');
            setNextBatch(Number(response.data.lastBatch) + 1);
        }

        getLastBatchNumber();
    }, []);

    useEffect(() => {
        if(extractTypeFilter && uploadedFileList) {
            setSelectAll(false);
            readDocumentOfx(uploadedFileList);
        }
    }, [extractTypeFilter, uploadedFileList]);

    // Functions

    function toggleChecked(checked: boolean) {
        var bankStatementArray  = bankStatement.extract; 
        bankStatement.extract.map((value: any, index: number) => {
            if (index > 0 && value.concilied === false) {
                bankStatementArray[index].checked = checked
            }
        });
        setBankStatement({ extract: bankStatementArray });
        setSelectAll(checked);
    }
    
    async function autoSelectBankAccount(numberBank: number, bankAccountNumber: string) {
        const foundBankAccounts = await BankAccountService.getBankAccountsFiltered({ numberBank, accountAndDigit: bankAccountNumber, situation: 'y' });
 
        const lastBankAccount = foundBankAccounts[foundBankAccounts.length - 1];

        setAccountSelected(lastBankAccount);
        setAccountIdSelected(lastBankAccount?.id ?? 0);

        return lastBankAccount?.id ?? 0;
    }

    function formatDate(date: string) {
        if (date) {
            var dateSplit = date.split("-");
            return dateSplit[2] +"/"+ dateSplit[1] +"/"+ dateSplit[0];
        }
    }

    async function modalSearch(extract: any) {
        var date = extract.date.split("/");
        
        setInitialDateSearch(date[2] +"-"+ date[1] +"-"+ date[0]);
        setFinalDateSearch(date[2] +"-"+ date[1] +"-"+ date[0]);
        setTitleModalSearch(extract.type == "Despesa" ? "Contas a pagar em aberto para conciliar" : "Contas a receber em aberto para conciliar");
        setExtractSelected(extract);
        setPages(0);

        const raw = {
            initialDate: initialDateSearch,
            finalDate: finalDateSearch,
            bank: accountIdSelected,
            value: extract.value
        }

        await api.post(extract.type == "Despesa" ? "/billsToPay/search" : "/billsToReceive/search", raw).then((response) => {
            setSearchData(response.data);
        });
        setShowModalSearchExtractSystem(true);
    }

    async function search() {
        const raw = {
            initialDate: initialDateSearch,
            finalDate: finalDateSearch,
            text: searchText,
            bank: accountIdSelected,
            value: extractSelected?.value
        }

        await api.post(extractSelected?.type == "Despesa" ? "/billsToPay/search" : "/billsToReceive/search", raw).then((response) => {
            setSearchData(response.data);
        });
    }

    function selectToConciliation() {
        var countSelected = 0;

        searchData.map((value: any, index: number) => {
            if (value.checked) {
                countSelected++;
            }
        });


        if (countSelected == 1) {
            var bankData = bankStatement.extract; 

            searchData.map((value: any, index: number) => {
                if (value.checked) {
                    var type = extractSelected?.type ?? "";

                    bankStatement.extract.map((valueBank: any, indexBank: number) => {
                        if (indexBank == Number(indexExtractSelected)) {
                            var date = value.dueDate.split("-");
                            
                            bankData[indexBank].conciliation = {
                                id: value.id,
                                type: type,
                                date: date[2] +"/"+ date[1] +"/"+ date[0],
                                value: value.amount,
                                title: value.name,
                                category: value.categoryName,
                                dreSubCategoryId: value.dreSubCategoryId,
                                centerCost: value.centerCost,
                                payment: value.payment,
                                supplierOrCustomer: type == "Despesa" ? value.supplier : value.customer,
                                valueApproximate: false,
                                validationErrors: {
                                    title: false,
                                    dreSubCategoryId: false,
                                    payment: false,
                                    supplierOrCustomer: false,
                                }
                            }
                        }
                    })
                }
            });

            setShowModalSearchExtractSystem(false);

        } else {
            alert("Por favor selecione apenas uma conta para conciliar!");
        }
    }

    async function getSystemStatement(bank: ConciliationBankExtract, bankId: number) {
        const raw = {
            date: bank.date,
            value: bank.value,
            bankAccount: bankId
        }

        let bankStatementObject: extract | null = null;

        const response = await api.post(bank.type == "Despesa" ? '/billsToPay/conciliation' : '/billsToReceive/conciliation', raw)
        var date = bank.date.split('-');

        if (response.data) {
            var id                              = response.data.id;
            var typeSystemExtract               = bank.type;
            var dateSystemExtract               = response.data.dueDate;
            var valueSystemExtract              = response.data.amount;
            var titleSystemExtract              = response.data.name;
            var categorySystemExtract           = response.data.categoryName;
            var dreSubCategoryIdSystemExtract   = response.data.dreSubCategoryId;  
            var centerCostSystemExtract         = String(response.data.centerCost || '');
            var paymentSystemExtract            = response.data.payment;
            var supplierOrCustomerSystemExtract = bank.type == "Despesa" ? response.data.supplier : response.data.customer;
            var conciliationExists = !!response.data.bankReconciliation;
            var status = response.data.status;
            var dueDate = response.data.dueDate;
            
            dateSystemExtract = dateSystemExtract.split("-");
            
            bankStatementObject = {
                checked: false,
                type: bank.type,
                date: date[2] +"/"+ date[1] +"/"+ date[0],
                value: bank.value,
                title: bank.title,
                category: categorySystemExtract,
                dreSubCategoryId: dreSubCategoryIdSystemExtract,
                centerCost: "",
                concilied: conciliationExists,
                conciliationInProgress: false,
                conciliation: {
                    id: id,
                    type: typeSystemExtract,
                    date: dateSystemExtract[2] +"/"+ dateSystemExtract[1] +"/"+ dateSystemExtract[0],
                    value: valueSystemExtract,
                    title: titleSystemExtract,
                    category: categorySystemExtract,
                    dreSubCategoryId: dreSubCategoryIdSystemExtract,
                    centerCost: centerCostSystemExtract,
                    payment: paymentSystemExtract,
                    supplierOrCustomer: supplierOrCustomerSystemExtract,
                    valueApproximate: false,
                    validationErrors: {
                        title: false,
                        dreSubCategoryId: false,
                        payment: false,
                        supplierOrCustomer: false,
                    },
                    status,
                    dueDate,
                },
            };


        } else {
            const approximateResponse = await api.post(bank.type == "Despesa" ? '/billsToPay/conciliationApproximate' : '/billsToReceive/conciliationApproximate', raw)
            var date = bank.date.split('-');

            if (approximateResponse.data) {
                var id                              = approximateResponse.data.id;
                var typeSystemExtract               = bank.type;
                var dateSystemExtract               = approximateResponse.data.dueDate;
                var valueSystemExtract              = approximateResponse.data.amount;
                var titleSystemExtract              = approximateResponse.data.name;
                var categorySystemExtract           = approximateResponse.data.categoryName;
                var dreSubCategoryIdSystemExtract   = approximateResponse.data.dreSubCategoryId;  
                var centerCostSystemExtract         = String(approximateResponse.data.centerCost || '');
                var paymentSystemExtract            = approximateResponse.data.payment;
                var supplierOrCustomerSystemExtract = bank.type == "Despesa" ? approximateResponse.data.supplier : approximateResponse.data.customer;
                
                dateSystemExtract = dateSystemExtract.split("-");
                    
                bankStatementObject = {
                    checked: false,
                    type: bank.type,
                    date: date[2] +"/"+ date[1] +"/"+ date[0],
                    value: bank.value,
                    title: bank.title,
                    category: categorySystemExtract,
                    dreSubCategoryId: dreSubCategoryIdSystemExtract,
                    centerCost: "",
                    concilied: false,
                    conciliationInProgress: false,
                    conciliation: {
                        id: id,
                        type: typeSystemExtract,
                        date: dateSystemExtract[2] +"/"+ dateSystemExtract[1] +"/"+ dateSystemExtract[0],
                        value: valueSystemExtract,
                        title: titleSystemExtract,
                        category: categorySystemExtract,
                        dreSubCategoryId: dreSubCategoryIdSystemExtract,
                        centerCost: centerCostSystemExtract,
                        payment: paymentSystemExtract,
                        supplierOrCustomer: supplierOrCustomerSystemExtract,
                        valueApproximate: true,
                        validationErrors: {
                            title: false,
                            dreSubCategoryId: false,
                            payment: false,
                            supplierOrCustomer: false,
                        },
                    }
                };
    
            } else {
                bankStatementObject = {
                    checked: false,
                    type: bank.type,
                    date: date[2] +"/"+ date[1] +"/"+ date[0],
                    value: bank.value,
                    title: bank.title,
                    category: "",
                    dreSubCategoryId: 0,
                    centerCost: "",
                    concilied: false,
                    conciliationInProgress: false,
                    conciliation: {
                        id: 0,
                        type: bank.type,
                        date: date[2] +"/"+ date[1] +"/"+ date[0],
                        value: bank.value,
                        title: bank.title,
                        category: "",
                        dreSubCategoryId: 0,
                        centerCost: "",
                        payment: "",
                        supplierOrCustomer: 0,
                        valueApproximate: false,
                        validationErrors: {
                            title: false,
                            dreSubCategoryId: false,
                            payment: false,
                            supplierOrCustomer: false,
                        },
                    }
                };
            }
        }

        return bankStatementObject;
    }

    function getNumberBankXml(xml: string) {
        var regex       = /(<BANKID>[0-9]*)/g;
        var xmlSubstr   = xml.substring(xml.indexOf('<BANKID>')); 
        var numberBank  = xmlSubstr.match(regex)?.toString().replace('<BANKID>', '');
        return numberBank;
    }

    async function fileReaderLoadOfxCallback(document: ProgressEvent<FileReader>, reader: FileReader, uploadedFile: File, readEncoding: string) {
        var xml    = null;

        xml = String(document.target?.result);

        if (getNumberBankXml(xml) == "001") {
            if (xml.includes('</CODE>')) {
                xml = xml.replaceAll('</CODE>', '');
            }

            if (xml.includes('</SEVERITY>')) {
                xml = xml.replaceAll('</SEVERITY>', '');
            }
        }

        var ofxJson         = parseXmlOfxToJson(xml);

        var encoding = ofxJson.header.ENCODING;

        if(encoding === 'USASCII' && readEncoding !== 'ascii') {
            reader.onload = (readerData) => fileReaderLoadOfxCallback(readerData, reader, uploadedFile, 'ascii');
            reader.readAsText(uploadedFile, 'ascii');
            return;
        }
        
        var transactions      = ofxJson.OFX.BANKMSGSRSV1.STMTTRNRS.STMTRS.BANKTRANLIST.STMTTRN;
        var numberBank        = ofxJson.OFX.BANKMSGSRSV1.STMTTRNRS.STMTRS.BANKACCTFROM.BANKID;
        var bankAccountNumber = ofxJson.OFX.BANKMSGSRSV1.STMTTRNRS.STMTRS.BANKACCTFROM.ACCTID;

        const bankStatementArray: extract[] = [extractDefaultValue];

        const bankId = await autoSelectBankAccount(Number(numberBank), bankAccountNumber)
        if (!bankId) {
            setMsgError("Não existe nenhuma conta no sistema relacionada a este extrato!");
            setShowModalError(true);
            setBankStatement({ extract: bankStatementArray });
            setShowExtract(false);
            setIsReadingFile(false);
            return;
        }

        if (transactions) {
            for (var index = (transactions.length - 1); index >= 0; index--) {

                var typeExtract   = 
                    transactions[index].TRNTYPE == "DEBIT" ?
                        "Despesa"
                        :
                        transactions[index].TRNTYPE == "PAYMENT" ?
                            "Despesa"
                            :
                            "Receita";
                var dateExtract   = transactions[index].DTPOSTED.replace("[-3:BRT]", "");
                var valueExtract  = transactions[index].TRNAMT;
                var titleExtract  = transactions[index].MEMO;

                if (valueExtract.includes('-')) {
                    valueExtract = valueExtract.replace('-', '');
                }
                
                const bankExtract: ConciliationBankExtract = {
                    type: typeExtract, 
                    date: dateExtract.substring(0, 4) +"-"+ dateExtract.substring(4, 6) +"-"+ dateExtract.substring(6, 8),
                    value: valueExtract,
                    title: titleExtract
                };
                
                bankStatementArray.push(await getSystemStatement(bankExtract, Number(bankId)));
            }

            bankStatementArray.sort(function (element1, element2) {
                var date1Split = element1.date ? element1.date.split('/') : "";
                var date2Split = element2.date ? element2.date.split('/') : "";
                
                var date1: any = new Date(date1Split[2] +"-"+ date1Split[1] +"-"+ date1Split[0]);
                var date2: any = new Date(date2Split[2] +"-"+ date2Split[1] +"-"+ date2Split[0]);

                return date1 - date2;
            });

            setBankStatement({
                extract: bankStatementArray.filter((extract, index) => {
                    bankStatementArray[index].checked = false;
                    if(!extract) {
                        return false;
                    }
    
                    if(index === 0) {
                        return true;
                    }
    
                    if(!extractTypeFilter) {
                        return true;
                    } else if (extractTypeFilter === 'all') {
                        return true;
                    } else if (extractTypeFilter === 'receive') {
                        return extract.type === 'Receita';
                    } else if (extractTypeFilter === 'pay') {
                        return extract.type === 'Despesa';
                    } else if (extractTypeFilter === 'foundBills') {
                        return !!extract.conciliation.id;
                    } else if (extractTypeFilter === 'newBills') {
                        return !extract.conciliation.id;
                    }
    
                    return false;
                })
            });
        }

        setIsReadingFile(false);
        setShowExtract(true);
    }

    
    async function readDocumentOfx(fileList: FileList | null | undefined) {
        const files = fileList;

        if (!files || !files[0]) {
            return;
        }

        setIsReadingFile(true);

        const document = files[0];

        if (document) {
            const extensionStartIndex = document.name.lastIndexOf('.');
            const extension = document.name.substring(extensionStartIndex).toUpperCase();

            if (extension !== '.OFX') {
                setMsgError("Arquivo enviado não é um OFX válido!");
                setShowModalError(true);
                setBankStatement({ extract: [extractDefaultValue] });
                setShowExtract(false);
                setIsReadingFile(false);
                return;
            }

            var reader = new FileReader();
            
            try {
                reader.onload = (readerData) => fileReaderLoadOfxCallback(readerData, reader, document, 'utf8');
                reader.readAsText(document, 'utf8');
                
            } catch(error) {
                console.log(error);
            }
        }
    }

    function verifyHasExtractSelected() {
        var hasSelected = false;

        bankStatement.extract.map((value: any, index: number) => {
            if (index > 0) {
                if (value.checked) {
                    hasSelected = true;
                }
            }
        });

        return hasSelected;
    }

    function getCountSelected() {
        var count = 0;
        bankStatement.extract.map((value: any, index: number) => {
            if (value.checked) {
                count++;
            }
        });

        return count;
    }

    function isChecked(value: any) {
        return value.checked == false;
    }

    function deleteExtractSelected() {
        var bank = bankStatement.extract;
        setBankStatement({ extract: bank.filter(isChecked) });
    }

    function searchExtractSystem(bankId: number) {
        // var bank = bankStatement.extract;

        // bankStatement.extract.map((value: any, index: number) => {
        //     const raw = {
        //         date: value.date,
        //         value: value.value,
        //         bankAccount: accountIdSelected
        //     }
            
        //     await api.post(value.type == "Despesa" ? '/billsToPay/conciliation' : '/billsToReceive/conciliation', raw).then((response) => {
        //         var date = value.date.split('-');
                
        //         if (response.data) {
        //             var id                      = response.data.id;
        //             var typeSystemExtract       = value.type;
        //             var dateSystemExtract       = response.data.dueDate;
        //             var valueSystemExtract      = response.data.amount;
        //             var titleSystemExtract      = response.data.name;
        //             var categorySystemExtract   = response.data.categoryName;
        //             var centerCostSystemExtract = response.data.centerCost;
                    
    
        //             dateSystemExtract           = dateSystemExtract.split("-");
                    
        //             bankStatementArray.push({
        //                 checked: false,
        //                 type: value.type,
        //                 date: date[2] +"/"+ date[1] +"/"+ date[0],
        //                 value: value.value,
        //                 title: value.title,
        //                 category: categorySystemExtract,
        //                 centerCost: "",
        //                 conciliation: {
        //                     id: id,
        //                     type: typeSystemExtract,
        //                     date: dateSystemExtract[2] +"/"+ dateSystemExtract[1] +"/"+ dateSystemExtract[0],
        //                     value: valueSystemExtract,
        //                     title: titleSystemExtract,
        //                     category: categorySystemExtract,
        //                     centerCost: centerCostSystemExtract
        //                 }
        //             });
    
        //         } else {
        //             bankStatementArray.push({
        //                 checked: false,
        //                 type: value.type,
        //                 date: date[2] +"/"+ date[1] +"/"+ date[0],
        //                 value: value.value,
        //                 title: value.title,
        //                 category: "",
        //                 centerCost: "",
        //                 conciliation: {
        //                     id: 0,
        //                     type: "",
        //                     date: "",
        //                     value: "",
        //                     title: "",
        //                     category: "",
        //                     centerCost: ""
        //                 }
        //             });
        //         }
    
        //         setBankStatement({ extract: bankStatementArray});
        //     }); 
        // });
    }
    
    async function executeConciliation() {
        if(!validateConciliationFields()) {
            setShowModalError(true);
            setShowModalConfirm(false);
            return
        }

        var countFinishConciliation = 0;
        var countSelected           = getCountSelected();       
        
        const bank = await BankAccountService.getBankAccountById(accountIdSelected);
        
        await Promise.all(
            bankStatement.extract.map(async (value, index) => {
                if (index > 0 && value.checked) {
                    
                    await conciliateExtractRow(value, bank);
                } 
            })
        );

        finishConciliation();
    }

    function finishConciliation() {
        setShowModalConfirm(false);
        setShowSuccessModal(true);
    }

    async function conciliateExtractRow(value: extract, bank: BankAccount|null) {
        if (value.conciliation.id) {  
            var date = value.date.split("/");                     
            var dataEdit = {
                name: value.conciliation.title,
                amount: formatToFloat(value.conciliation.value),
                remaining: formatToFloat(value.conciliation.value),
                status: "paid",
                bankAccount: accountIdSelected,
                nameBank: accountIdSelected ? bank ? bank.nameBank : null : null,
                categoryName: value.conciliation.category,
                dreSubCategoryId: value.conciliation.dreSubCategoryId,
                centerCost: value.conciliation.centerCost ? parseInt(value.conciliation.centerCost) : null,
                payment: value.conciliation.payment,
                customer: value.type == "Receita" ? value.conciliation.supplierOrCustomer : undefined,
                supplier: value.type == "Despesa" ? value.conciliation.supplierOrCustomer : undefined,
                dueDate: date[2] +"-"+ date[1] +"-"+ date[0],
                issueDate: date[2] +"-"+ date[1] +"-"+ date[0],
            }

            const response = await api.put(value.type == "Despesa" ? `billsToPay/${value.conciliation.id}` : `billsToReceive/${value.conciliation.id}`, dataEdit);
            await saveReconciliationHistory(response.data, value.type);
            return response.data;

        } else {
            var date = value.date.split("/");
            
            var dataNew;
            if (value.type == "Despesa") {
                dataNew = {
                    name: value.conciliation.title,
                    amount: formatToFloat(value.conciliation.value),
                    remaining: formatToFloat(value.conciliation.value),
                    status: "paid",
                    bankAccount: accountIdSelected,
                    nameBank: accountIdSelected ? bank ? bank.nameBank : null : null,
                    categoryName: value.conciliation.category,
                    dreSubCategoryId: value.conciliation.dreSubCategoryId,
                    centerCost: value.conciliation.centerCost ? parseInt(value.conciliation.centerCost) : null,
                    dueDate: date[2] +"-"+ date[1] +"-"+ date[0],
                    issueDate: date[2] +"-"+ date[1] +"-"+ date[0],
                    payment: value.conciliation.payment,
                    supplier: value.conciliation.supplierOrCustomer,
                }

            } else {
                dataNew = {
                    name: value.conciliation.title,
                    amount: formatToFloat(value.conciliation.value),
                    remaining: formatToFloat(value.conciliation.value),
                    status: "paid",
                    bankAccount: accountIdSelected,
                    nameBank: accountIdSelected ? bank ? bank.nameBank : null : null,
                    categoryName: value.conciliation.category,
                    dreSubCategoryId: value.conciliation.dreSubCategoryId,
                    centerCost: value.conciliation.centerCost ? parseInt(value.conciliation.centerCost) : null,
                    dueDate: date[2] +"-"+ date[1] +"-"+ date[0],
                    issuanceDate: date[2] +"-"+ date[1] +"-"+ date[0],
                    payment: value.conciliation.payment,
                    customer: value.conciliation.supplierOrCustomer,
                }
            }

            const response = await api.post(value.type == "Despesa" ? "billsToPay" : "billsToReceive", dataNew);
            await saveReconciliationHistory(response.data, value.type);

            return response.data;
        }
    }

    function validateConciliationFields() {
        if(!verifyHasExtractSelected()) {
            setMsgError("Selecione pelo menos uma movimentação do banco para conciliar!");
            return false;
        }

        let systemExtractHasError = false;

        bankStatement.extract.forEach((extract, index) => {
            systemExtractHasError = !validateSingleExtractRow(extract, index);
        });

        if (systemExtractHasError) {
            setMsgError("Preencha corretamente os campos para dar baixa nos lançamentos selecionados!");
            return false;
        }

        return true;
    }

    function validateSingleExtractRow(extractRow: extract, index: number) {
        let hasError = false;

        extractRow.conciliation.validationErrors = {
            title: false,
            dreSubCategoryId: false,
            payment: false,
            supplierOrCustomer: false,
        };

        if (extractRow.checked && index > 0) {
            if (!extractRow.conciliation.title) {
                hasError = true;
                extractRow.conciliation.validationErrors.title = true;
            }

            if (!extractRow.conciliation.dreSubCategoryId) {
                hasError = true;
                extractRow.conciliation.validationErrors.dreSubCategoryId = true;
            }

            if (!extractRow.conciliation.payment) {
                hasError = true;
                extractRow.conciliation.validationErrors.payment = true;
            }

            if (extractRow.type === 'Receita' && !extractRow.conciliation.supplierOrCustomer) {
                hasError = true;
                extractRow.conciliation.validationErrors.supplierOrCustomer = true;
            }
        }

        return !hasError;
    }

    async function saveReconciliationHistory(bill: BillsToPay | BillsToReceive, type: string) {
        const raw = {
            accountBankId: Number(bill.bankAccount),
            billType: type === 'Despesa' ? 'pay' : 'receive',
            billId: bill.id,
            batch: nextBatch,
        }

        await api.post('/bankReconciliation', raw);
    }

    async function handleExecuteConciliationOnSingleRow(extractIndex: number) {
        if (!extractIndex) return false;
        
        const extract = bankStatement.extract.find((row, index) => index === extractIndex);
        
        if (!extract) return false;

        setBankStatement((prevState) => {
            return {
                extract: prevState.extract.map((value, index) => {
                    if (index === extractIndex) {
                        return {
                            ...value,
                            conciliationInProgress: true,
                            checked: true,
                        };
                    } else {
                        return value;
                    }
                })
            };
        });

        extract.checked = true;

        if (!validateSingleExtractRow(extract, extractIndex)) {
            setMsgError("Preencha corretamente os campos para dar baixa no lançamento!");
            setShowModalError(true);
            setShowModalConfirmSingle(false);
            setBankStatement((prevState) => {
                return {
                    extract: prevState.extract.map((value, index) => {
                        if (index === extractIndex) {
                            return { ...value, conciliationInProgress: false, };
                        } else {
                            return value;
                        }
                    })
                };
            });
            return false;
        }    
        
        const bank = await BankAccountService.getBankAccountById(accountIdSelected);
        
        const conciliationBill = await conciliateExtractRow(extract, bank);
        var date = conciliationBill.dueDate.split('-');

        setShowModalConfirmSingle(false);
        setIndexBankStatementToConciliate(0);
        setBankStatement((prevState) => {
            return {
                extract: prevState.extract.map((value, index) => {
                    if (index === extractIndex) {
                        return {
                            ...value,
                            conciliationInProgress: false,
                            concilied: true,
                            checked: false,
                            conciliation: {
                                id: conciliationBill.id,
                                type: value.type,
                                date: date[2] +"/"+ date[1] +"/"+ date[0],
                                value: value.value,
                                title: value.title,
                                category: conciliationBill.nameCategory,
                                dreSubCategoryId: conciliationBill.dreSubCategoryId,
                                centerCost: conciliationBill.centerCost,
                                payment: conciliationBill.payment,
                                supplierOrCustomer: conciliationBill.customer || conciliationBill.supplier,
                                valueApproximate: false,
                                validationErrors: {
                                    title: false,
                                    dreSubCategoryId: false,
                                    payment: false,
                                    supplierOrCustomer: false,
                                },
                            },
                        };
                    } else {
                        return value;
                    }
                })
            };
        });

        return true;
    }

    function unLinkExtract(index: number) {
        const bankStatementArray  = bankStatement.extract; 

        bankStatement.extract.map((value: any, indexStatement: number) => {
            if (indexStatement == index) {
                bankStatementArray[indexStatement].conciliation.id                  = 0;
                bankStatementArray[indexStatement].conciliation.type                = bankStatementArray[indexStatement].type;
                bankStatementArray[indexStatement].conciliation.date                = bankStatementArray[indexStatement].date;
                bankStatementArray[indexStatement].conciliation.value               = bankStatementArray[indexStatement].value;
                bankStatementArray[indexStatement].conciliation.title               = bankStatementArray[indexStatement].title;
                bankStatementArray[indexStatement].conciliation.category            = "";
                bankStatementArray[indexStatement].conciliation.dreSubCategoryId    = 0;
                bankStatementArray[indexStatement].conciliation.centerCost          = "";
                bankStatementArray[indexStatement].conciliation.supplierOrCustomer  = 0;
                bankStatementArray[indexStatement].conciliation.valueApproximate    = false;
            }
        });
        
        setBankStatement({ extract: bankStatementArray });
    }

    const handleClickEditConciliedBill = useCallback((index: number) => {
        setBankStatement((prevState) => ({
                extract: prevState.extract.map((extractRow, extractIndex) => {
                    if (extractIndex === index) {
                        return {
                            ...extractRow,
                            isEdittingConcilied: true,
                        };
                    }
    
                    return extractRow;
                })
            }))
    }, []);

    const handleClickEditCancel = useCallback((index: number) => {
        setBankStatement((prevState) => ({
                extract: prevState.extract.map((extractRow, extractIndex) => {
                    if (extractIndex === index) {
                        return {
                            ...extractRow,
                            isEdittingConcilied: false,
                        };
                    }
    
                    return extractRow;
                })
            }))

    }, []);

    async function handleClickEditSave(index: number) {
        const success = await handleExecuteConciliationOnSingleRow(index);

        if (success) {
            setBankStatement((prevState) => ({
                extract: prevState.extract.map((extractRow, extractIndex) => {
                    if (extractIndex === index) {
                        return {
                            ...extractRow,
                            isEdittingConcilied: false,
                        };
                    }
    
                    return extractRow;
                })
            }));
        }
    }

    const handleClickUploadZone = useCallback(() => {
        if (inputFileRef.current) {
            inputFileRef.current.click();
        }
    }, []);

    const handleClickChangeUploadedFile = useCallback(() => {
        setUploadedFileList(inputFileRef.current?.files);
    }, []);


    const handleDropFile = useCallback((e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();

        setIsDraggingFileOver(false);

        setUploadedFileList(e.dataTransfer.files);
    }, []);

    const handleDragOver = useCallback((e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
    }, []);

    const handleDragEnter = useCallback((e: React.DragEvent<HTMLDivElement>) => {
        setIsDraggingFileOver(true);
    }, []);
    const handleDragLeave = useCallback((e: React.DragEvent<HTMLDivElement>) => {
        setIsDraggingFileOver(false);
    }, []);
    
    const handleChangePage = useCallback((next: number) => {
        setPages(next);
    }, []);

    const handleRowsPerPage = useCallback((value: number) => {
        setRowsPerPage(value);
    }, []);

    const handleClickReconciledBills = useCallback(() => {
        pushHistory(`${pathname}/conciliados`);
    }, [pathname]);
        
    const reverse = async(id: number, type: string, index: number) => {
        setLoading(1);
        try {
            let data = {
                id,
                type: type == "Receita" ? "receive" : "pay",
            };
            let resp = await api.post('/bankReconciliation/deleteReconciliation', data);
            if (resp.data.status) {
                setBankStatement((prevState) => ({
                    extract: prevState.extract.map((extractRow, extractIndex) => {
                        if (extractIndex === index) {
                            return {
                                ...extractRow,
                                concilied: false,
                            };
                        }
                        return extractRow;
                    })
                }));
            }
        } catch (error) {
            console.log(error);
        }
        setLoading(0);
    }

    return (
        <div className="row card card-body pt-4 newProductWrapper">
            <NewCustomerModal
                showModal={showModalNewCustomer}
                setShowModal={setShowModalNewCustomer}
                onCreateCustomer={handleCreateCustomer}
                defaultData={newCustomerDefaultData}
                allowedType={typeCustomerBeingAdded}
            />

            <ModalSuccess
               msgModal={'Conciliação realizada com sucesso!'}
               showModal={showSuccessModal}
               setShowModal={setShowSuccessModal}
               redirect={`${pathname}/conciliados`}
            />
            <Modal
                show={showModalConfirm}
                onHide={() => setShowModalConfirm(false)}
                aria-labelledby="contained-modal-warning"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="d-flex align-items-center">
                        <i className="flaticon2-correct icon-xl mr-3" style={{ color: "green" }}></i>
                        Conciliar Selecionados
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <b>Deseja confirmar a conciliação e baixa das contas selecionadas ?</b>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModalConfirm(!showModalConfirm)}>Cancelar</Button>
                    <Button variant="primary" onClick={() => executeConciliation()}>Confirmar</Button>
                </Modal.Footer>
            </Modal>

            <Modal
                show={showModalConfirmSingle}
                onHide={() => setShowModalConfirmSingle(false)}
                aria-labelledby="contained-modal-warning"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="d-flex align-items-center">
                        <i className="flaticon2-correct icon-xl mr-3" style={{ color: "green" }}></i>
                        Conciliar
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <b>Deseja confirmar a conciliação e baixa da conta selecionada ?</b>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModalConfirmSingle(false)}>Cancelar</Button>
                    <Button variant="primary" onClick={() => handleExecuteConciliationOnSingleRow(indexBankStatementToConciliate)}>Confirmar</Button>
                </Modal.Footer>
            </Modal>

            <Modal
                show={showModalError}
                onHide={() => setShowModalError(false)}
                aria-labelledby="contained-modal-warning"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="d-flex align-items-center">
                        <i className="flaticon2-warning icon-xl text-warning mr-3"></i>
                        Atenção
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <b>{msgError}</b>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowModalError(!showModalError)}>Fechar</Button>
                </Modal.Footer>
            </Modal>

            <Modal
                show={showModalSearchExtractSystem}
                onHide={() => setShowModalSearchExtractSystem(false)}
                aria-labelledby="contained-modal-warning"
                size="xl"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title className="d-flex align-items-center col-lg-8">
                        {titleModalSearch}
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div className="row">
                        <div className="col-lg-3">
                            <TextField
                                type="date"
                                label="De"
                                margin="normal"
                                variant="outlined"
                                size="small"
                                value={initialDateSearch}
                                onChange={(e) => setInitialDateSearch(e.target.value)}
                                InputProps={{ inputProps: {max: finalDateSearch} }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={user.isAccountant == "y" ? true : false}
                            />
                        </div>
                        <div className="col-lg-3">
                            <TextField
                                type="date"
                                label="Até"
                                margin="normal"
                                variant="outlined"
                                size="small"
                                value={finalDateSearch}
                                onChange={(e) => setFinalDateSearch(e.target.value)}
                                InputProps={{ inputProps: {min: initialDateSearch} }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                disabled={user.isAccountant == "y" ? true : false}
                            />
                        </div>
                        <div className="col-lg-4">
                            <TextField
                                size="small"
                                label='Pesquisar'
                                placeholder='Informe o nome do lançamento'
                                margin='normal'
                                variant='outlined'
                                value={searchText}
                                onChange={(e) => setSearchText(e.target.value)}
                                disabled={user.isAccountant == "y" ? true : false}
                            />
                        </div>

                        {
                            user.isAccountant == "n"
                            ?
                            <div className="col-lg-2 d-flex align-items-center">
                                <Button variant="primary" className="mt-2" onClick={() => search()}>Buscar</Button>
                            </div>
                            : <></>
                        }
                    </div>

                    <div className="row mt-3">
                        <div className="col-lg-12">
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell padding="default">
                                            <strong>Data</strong>
                                        </TableCell>
                                        <TableCell padding="default">
                                            <strong>Lançamento</strong>
                                        </TableCell>
                                        <TableCell padding="default">
                                            <strong>Valor</strong>
                                        </TableCell>
                                        <TableCell padding="default">
                                            <strong>Forma de pagamento</strong>
                                        </TableCell>
                                        <TableCell padding="default">
                                            <strong>Selecione</strong>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {
                                        searchData.length 
                                        ? 
                                            searchData.map((value: any, index: number) => {
                                                return (
                                                    <TableRow key={index}>
                                                        <TableCell scope="row" padding="default">
                                                            {formatDate(value.dueDate)}
                                                        </TableCell>
                                                        <TableCell scope="row" padding="default">
                                                            {value.name}
                                                        </TableCell>
                                                        <TableCell scope="row" padding="default">
                                                            {formatDecimal.format(value.amount)}
                                                        </TableCell>
                                                        <TableCell scope="row" padding="default">
                                                            {value.payment ?? "-"}
                                                        </TableCell>
                                                        <TableCell scope="row" padding="default">
                                                            <Checkbox
                                                                value={value.checked}
                                                                inputProps={{
                                                                'aria-label': 'success checkbox',
                                                                }}
                                                                className="pt-0 pb-0"
                                                                onChange={(e) => {
                                                                    var data = searchData;
                                                                    data[index].checked = e.target.checked;
                                                                    setSearchData(data);
                                                                }}
                                                            /> 
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            })
                                        :
                                        <TableRow>
                                            <TableCell colSpan={5} scope="row" padding="default" className="text-center">
                                                <p>Nenhuma conta neste período foi encontrada...</p>
                                            </TableCell>
                                        </TableRow>
                                    }
                                    

                                    
                                </TableBody>
                            </Table>

                            <TablePagination
                                page={pages}
                                component="div"
                                count={searchData.length}
                                rowsPerPage={rowsPerPage}
                                rowsPerPageOptions={[5, 10, 25]}
                                backIconButtonProps={{
                                    'aria-label': 'Página Anterior',
                                }}
                                nextIconButtonProps={{
                                    'aria-label': 'Próxima Página',
                                }}
                                onChangePage={(_, next) => handleChangePage(next)}
                                onChangeRowsPerPage={(evt) => handleRowsPerPage(Number(evt.target.value))}
                            />
                        </div>
                    </div>
                    <Modal.Footer>
                        <Button variant="secondary" className="mr-3" onClick={() => setShowModalSearchExtractSystem(!showModalSearchExtractSystem)}>Fechar</Button>
                        {
                            user.isAccountant == "n"
                            ?
                            <Button variant="primary" onClick={() => selectToConciliation()}>Selecionar</Button>
                            : <></>
                        }
                    </Modal.Footer>
                </Modal.Body>
            </Modal>

            <div className="row d-flex justify-content-center mt-2">
                <div
                    className={`col-lg-4 d-flex flex-column justify-content-center align-items-center p-5 hover hover-drop-box ${isDraggingFileOver && 'active-hover-drop-box'}`}
                    style={{ border: "4px dashed #ccc", background: "#fefefe" }}
                    onClick={() => handleClickUploadZone()}
                    onDrop={handleDropFile}
                    onDragOver={handleDragOver}
                    onDragEnter={handleDragEnter}
                    onDragLeave={handleDragLeave}
                >                                      
                    <div className="d-flex flex-column justify-content-between">
                        <div className="w-100 d-flex justify-content-center">
                            <img src="/media/icons/folder.png" className='mr-5' />
                        </div>
                        {!isReadingFile && (
                            <div className="d-flex flex-column justify-content-between">
                                <h5 className="text-center mt-3 mb-5">Clique aqui ou arraste um arquivo para fazer upload</h5>
                                <h6 className="text-center mt-5">Importe o arquivo para realizar a conciliação</h6>
                                <input
                                    type='file'
                                    id='formFile'
                                    accept='.ofx'
                                    onChange={(e) => handleClickChangeUploadedFile()}
                                    style={{ display: "none" }}
                                    disabled={user.isAccountant == "y" ? true : false}
                                    ref={inputFileRef}
                                />
                            </div>
                        )}
                        {isReadingFile && (
                            <div className="d-flex mt-5 align-items-center">
                                <Spinner
                                    as="span"
                                    animation="border"
                                    role="status"
                                    aria-hidden="true"
                                />
                                <span className='ml-2'>Aguarde...</span>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <div className="row mt-10">
                <div className="col d-flex justify-content-center">
                    <Button
                        type="button"
                        variant="success"
                        className="ml-3"
                        onClick={() => handleClickReconciledBills()}
                    >
                        Lançamentos Conciliados
                    </Button>
                </div>
            </div>

            {
                showExtract
                ?
                    <>
                        <div className="row d-flex justify-content-center">
                            <div className="col-lg-7 d-flex flex-row align-items-center justify-content-around mt-3">
                                <ApiResourceSelect
                                    style={{ width: '100%' }}
                                    label="Conta bancária"
                                    getOptionLabel={(option: BankAccount) => `${option.nameBank} - ${option.name}`}
                                    value={accountIdSelected}
                                    required
                                    onSelect={(option) => {
                                        setAccountIdSelected(option?.id ?? 0);
                                        searchExtractSystem(option?.id ?? 0);
                                    }}
                                    apiSearchHandler={(typedText) => BankAccountService.getBankAccountsFiltered({ name: typedText, situation: 'y' })}
                                    getSelectedOption={(loadedOptions) => {
                                        if(!accountIdSelected) return null;
                                        return loadedOptions.find((option) => option.id === Number(accountIdSelected)) ?? BankAccountService.getBankAccountById(accountIdSelected)
                                    }}
                                    disabled={user.isAccountant == "y" ? true : false}
                                />
                                    
                                {
                                    user.isAccountant == "n"
                                    ?
                                    <div className="d-flex align-items-center">
                                        <button type="button" className="btn btn-danger ml-3 mr-3 mt-2" onClick={() => deleteExtractSelected()}>Excluir</button>
                                        <button type="button" className="btn btn-primary mt-2" onClick={() => setShowModalConfirm(true)}>Conciliar Selecionados</button>
                                    </div>
                                    : <></>
                                }
                            </div>
                            
                        </div>

                        <div className="row mt-3">
                            <div className="col-lg-12">
                                <div className="col-lg-4">
                                    <TextField
                                        select
                                        label="Tipo de movimentação"
                                        margin="normal"
                                        size="small"
                                        variant="outlined"
                                        value={extractTypeFilter}
                                        onChange={(e) => setExtractTypeFilter(e.target.value)}
                                    >
                                        <MenuItem key="1" value="all">Todos</MenuItem>
                                        <MenuItem key="2" value="receive">Receitas</MenuItem>
                                        <MenuItem key="3" value="pay">Despesas</MenuItem>
                                        <MenuItem key="4" value="foundBills">Registros Encontrados</MenuItem>
                                        <MenuItem key="5" value="newBills">Registros Novos</MenuItem>
                                    </TextField>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-12 mt-5 border-top pt-5">                
                            <div className="row d-flex justify-content-between">
                                <div className="col-lg-5">
                                    <div className="row d-flex align-items-start">
                                        
                                        <div className="col-lg-12">
                                            <h5>
                                                <Checkbox
                                                    inputProps={{
                                                    'aria-label': 'success checkbox',
                                                    }}
                                                    className="pt-0 pb-0"
                                                    onChange={(e) => toggleChecked(e.target.checked)}
                                                    checked={selectAll}
                                                    disabled={user.isAccountant == "y" ? true : false}
                                                /> 
                                                
                                                Extrato do Banco
                                            </h5>                      
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-5">
                                    <h5>Extrato do Sistema</h5>
                                </div>
                            </div>

                            <div className="row d-flex justify-content-between">
                                {
                                    bankStatement.extract.map((extract, index) => {
                                        if (index > 0) {
                                            return (
                                                <React.Fragment key={"extract-"+ index}>
                                                    <div className="col-lg-5 d-flex align-items-center justify-content-center">
                                                        <div className="card shadow-sm mb-3 w-100">
                                                            <div className="card-header p-2 d-flex align-items-center justify-content-between">
                                                                <div>
                                                                    {!extract.concilied && (
                                                                        <Checkbox
                                                                            checked={extract.checked}
                                                                            inputProps={{
                                                                            'aria-label': 'success checkbox',
                                                                            }}
                                                                            onClick={(e) => {
                                                                                var bank = bankStatement.extract;
                                                                                bank[index].checked = !bank[index].checked;
                                                                                setBankStatement({ extract: bank});
                                                                            }}
                                                                            disabled={user.isAccountant == "y" ? true : false}
                                                                        />
                                                                    )}
                                                                    

                                                                    <b>{extract.type} - {extract.date}</b>
                                                                </div>

                                                                <b style={{ color: extract.type == "Receita" ? "green" : "red" }}>{ extract.type == "Despesa" ? "-" : ""}{ formatDecimal.format(formatToFloat(extract.value))}</b>
                                                            </div>
                                                            <div className="card-body">                                                            
                                                                <h5>{extract.title}</h5>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-2 d-flex align-items-center justify-content-center">
                                                        {extract.concilied && (
                                                            <>
                                                                <ValidationBadge label="Conciliado" variant="success" title="Conciliado" />
                                                                <Button
                                                                    variant={"warning"}
                                                                    onClick={() => reverse(extract.conciliation.id, extract.type, index)}
                                                                >
                                                                    {
                                                                        loading ? 
                                                                            <>
                                                                                <Spinner
                                                                                    as="span"
                                                                                    animation="border"
                                                                                    size="sm"
                                                                                    role="status"
                                                                                    aria-hidden="true"
                                                                                />
                                                                                <span className='ml-2'>Aguarde...</span>
                                                                            </> : 
                                                                            <>
                                                                                <span>Estorno</span>
                                                                            </>
                                                                    }
                                                                </Button>
                                                            </>
                                                        )}

                                                        {!extract.concilied && (
                                                            <Button
                                                                size="lg"
                                                                variant="primary"
                                                                disabled={extract.conciliationInProgress}
                                                                onClick={() => {
                                                                    setIndexBankStatementToConciliate(index);
                                                                    setShowModalConfirmSingle(true);
                                                                }}
                                                            >
                                                                {extract.conciliationInProgress ? 
                                                                <>
                                                                    <Spinner
                                                                        as="span"
                                                                        animation="border"
                                                                        size="sm"
                                                                        role="status"
                                                                        aria-hidden="true"
                                                                    />
                                                                    <span className='ml-2'>Aguarde...</span>
                                                                </> : 
                                                                <>
                                                                    <span>Conciliar</span>
                                                                </>}
                                                            </Button>
                                                        )}
                                                    </div>

                                                    <div key={"system-"+ index} className="col-lg-5 d-flex align-items-center justify-content-center">
                                                        <div className="card shadow-sm mb-3 w-100">
                                                            <div className="card-header p-5 d-flex align-items-center justify-content-between">
                                                                <div>
                                                                    <b>{extract.conciliation.type} - {extract.conciliation.date}</b>   
                                                                </div>

                                                                <b style={{ color: extract.conciliation.type == "Receita" ? "green" : "red" }}>
                                                                    { extract.conciliation.type == "Despesa" ? "-" : ""}
                                                                    {formatDecimal.format(formatToFloat(extract.conciliation.value))}  
                                                                </b>
                                                            </div>
                                                            <div className="card-body pt-0 pb-2">
                                                                <div className="row">
                                                                    <div className="col-lg-12 mt-3 d-flex align-items-center justify-content-between">
                                                                        <div className="d-flex flex-row align-items-center">
                                                                            {
                                                                                extract.conciliation.id
                                                                                ?
                                                                                <Badge variant="primary" style={{ background: "blue" }}><a style={{ color: "white" }} href={extract.conciliation.type == "Despesa" ? "/contas-a-pagar/"+extract.conciliation.id : "/contas-a-receber/"+extract.conciliation.id} target="_blank">{extract.conciliation.type == "Receita" ? "Conta a Receber Nº"+ extract.conciliation.id : "Conta a Pagar Nº"+ extract.conciliation.id}</a></Badge>
                                                                                :
                                                                                <Badge variant="primary" style={{ background: "green", color: "white" }}>Novo Registro</Badge>
                                                                            }
                                                                            {
                                                                                extract.conciliation.valueApproximate
                                                                                ?
                                                                                <OverlayTrigger
                                                                                    placement="top"
                                                                                    overlay={
                                                                                    <Tooltip id='tooltip-top'>
                                                                                        Desvincular Extrato
                                                                                    </Tooltip>
                                                                                    }
                                                                                >
                                                                                    <Button 
                                                                                        size="sm" 
                                                                                        variant="warning" 
                                                                                        className="d-flex flex-row align-items-center justify-content-center ml-2" 
                                                                                        style={{ padding: "3px 5px" }}
                                                                                        onClick={() => unLinkExtract(index)}
                                                                                        disabled={user.isAccountant == "y" ? true : false}
                                                                                    >
                                                                                        <i className="flaticon2-information mr-1" style={{ height: "14px" }}></i>
                                                                                        Valor Aproximado
                                                                                    </Button>
                                                                                </OverlayTrigger>
                                                                                :
                                                                                <></>
                                                                            }
                                                                        </div>

                                                                        {
                                                                            user.isAccountant == "n"
                                                                            ?
                                                                            <div className="d-flex flex-row align-items-center justify-content-between">
                                                                                {extract.concilied && !extract.isEdittingConcilied && (
                                                                                    <OverlayTrigger
                                                                                        placement="top"
                                                                                        overlay={
                                                                                        <Tooltip id='tooltip-top'>
                                                                                            Editar
                                                                                        </Tooltip>
                                                                                        }
                                                                                    >
                                                                                        <Button
                                                                                            className="btn-light-primary p-2 mr-3"
                                                                                            variant="primary"
                                                                                            type="button"
                                                                                            onClick={() => handleClickEditConciliedBill(index)}
                                                                                        >
                                                                                            <i className="flaticon2-edit p-0"></i>
                                                                                        </Button>
                                                                                    </OverlayTrigger>
                                                                                )}

                                                                                {extract.concilied && extract.isEdittingConcilied && (
                                                                                    <>
                                                                                        <OverlayTrigger
                                                                                            placement="top"
                                                                                            overlay={
                                                                                            <Tooltip id='tooltip-top'>
                                                                                                Salvar
                                                                                            </Tooltip>
                                                                                            }
                                                                                        >
                                                                                            <Button
                                                                                                className="btn-light-success p-2 mr-3"
                                                                                                variant="success"
                                                                                                type="button"
                                                                                                disabled={extract.conciliationInProgress}
                                                                                                onClick={() => handleClickEditSave(index)}
                                                                                            >
                                                                                                <i className="flaticon2-check-mark p-0"></i>
                                                                                            </Button>
                                                                                        </OverlayTrigger>
                                                                                        <OverlayTrigger
                                                                                            placement="top"
                                                                                            overlay={
                                                                                            <Tooltip id='tooltip-top'>
                                                                                                Cancelar edição
                                                                                            </Tooltip>
                                                                                            }
                                                                                        >
                                                                                            <Button
                                                                                                className="btn-light-danger p-2 mr-3"
                                                                                                variant="danger"
                                                                                                type="button"
                                                                                                disabled={extract.conciliationInProgress}
                                                                                                onClick={() => handleClickEditCancel(index)}
                                                                                            >
                                                                                                <i className="flaticon2-cross p-0"></i>
                                                                                            </Button>
                                                                                        </OverlayTrigger>
                                                                                    </>
                                                                                )}

                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-link p-0"
                                                                                    onClick={() => {
                                                                                        modalSearch(extract); setIndexExtractSelected(String(index))
                                                                                    }}
                                                                                >
                                                                                    <img src="/media/icons/searchExtract.png"/>
                                                                                </button>
                                                                            </div>
                                                                            : <></>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                
                                                                <div className="row">
                                                                    <div className="col-lg-12">
                                                                        <TextField
                                                                            size="small"
                                                                            label='Título da transação'
                                                                            margin='normal'
                                                                            variant='outlined'
                                                                            value={extract.conciliation.title}
                                                                            onChange={(e) => {
                                                                                var bank = bankStatement.extract;
                                                                                bank[index].conciliation.title = e.target.value;
                                                                                setBankStatement({ extract: bank});
                                                                            }}
                                                                            disabled={user.isAccountant == "y" ? true : false || (extract.concilied && !extract.isEdittingConcilied)}
                                                                            error={extract.conciliation.validationErrors.title}
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div className="row">
                                                                    <div className="col-lg-12">
                                                                        <ApiResourceSelect
                                                                            style={{ width: "100%" }}
                                                                            label="Plano de Contas"
                                                                            getOptionLabel={(option: SubCategory) => option.name}
                                                                            value={extract.conciliation.dreSubCategoryId}
                                                                            onSelect={(option) => {
                                                                                var bank = bankStatement.extract;
                                                                                bank[index].conciliation.dreSubCategoryId = option?.id ?? 0;
                                                                                bank[index].conciliation.category = option?.name ?? '';
                                                                                setBankStatement({ extract: bank});
                                                                            }}
                                                                            apiSearchHandler={(typedText) => (
                                                                                DreCategoryService.getDreSubCategoriesFiltered(
                                                                                    { name: typedText },
                                                                                    extract.type === 'Despesa' ? 'expense' : 'revenue'
                                                                                )
                                                                            )}
                                                                            disabled={user.isAccountant == "y" ? true : false || (extract.concilied && !extract.isEdittingConcilied)}
                                                                            hasError={extract.conciliation.validationErrors.dreSubCategoryId}
                                                                            getSelectedOption={(loadedOptions) => {
                                                                                if(!extract.conciliation.dreSubCategoryId) return null;
                                                                                return loadedOptions.find((option) => option.id === Number(extract.conciliation.dreSubCategoryId)) ?? DreCategoryService.getDreSubCategoryById(extract.conciliation.dreSubCategoryId)
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-lg-12 d-flex align-items-center">                                                                    
                                                                        {
                                                                            extract.type == "Despesa"
                                                                            ?
                                                                                <ApiResourceSelect
                                                                                    style={{ width: "100%" }}
                                                                                    label="Fornecedor"
                                                                                    getOptionLabel={(option: Customer) => `${option.id} - ${option.name}`}
                                                                                    value={extract.conciliation.supplierOrCustomer}
                                                                                    onSelect={(option) => {
                                                                                        var bank = bankStatement.extract;
                                                                                        bank[index].conciliation.supplierOrCustomer = option ? option.id : 0;
                                                                                        setBankStatement({ extract: bank });
                                                                                    }}
                                                                                    apiSearchHandler={(typedText) => CustomerService.getCustomersFiltered({ name: typedText, nameWithId: true, typeRegister: 'supplier' })}
                                                                                    getSelectedOption={(loadedOptions) => {
                                                                                        if(!extract.conciliation.supplierOrCustomer) return null;
                                                                                        return loadedOptions.find((option) => option.id === Number(extract.conciliation.supplierOrCustomer)) ?? CustomerService.getCustomerById(extract.conciliation.supplierOrCustomer)
                                                                                    }}
                                                                                    disabled={user.isAccountant == "y" ? true : false || (extract.concilied && !extract.isEdittingConcilied)}
                                                                                    renderAddButton={(typedText) => <ApiResourceSelectAddButton label="Adicionar Fornecedor" onClick={() => handleClickAddCustomer(typedText, 'supplier', index)} />}
                                                                                />
                                                                            :
                                                                                <ApiResourceSelect
                                                                                    style={{ width: "100%" }}
                                                                                    label="Cliente"
                                                                                    getOptionLabel={(option: Customer) => `${option.id} - ${option.name}`}
                                                                                    value={extract.conciliation.supplierOrCustomer}
                                                                                    onSelect={(option) => {
                                                                                        var bank = bankStatement.extract;
                                                                                        bank[index].conciliation.supplierOrCustomer = option ? option.id : 0;
                                                                                        setBankStatement({ extract: bank });
                                                                                    }}
                                                                                    apiSearchHandler={(typedText) => CustomerService.getCustomersFiltered({ name: typedText, nameWithId: true, typeRegister: 'customer' })}
                                                                                    getSelectedOption={(loadedOptions) => {
                                                                                        if(!extract.conciliation.supplierOrCustomer) return null;
                                                                                        return loadedOptions.find((option) => option.id === Number(extract.conciliation.supplierOrCustomer)) ?? CustomerService.getCustomerById(extract.conciliation.supplierOrCustomer)
                                                                                    }}
                                                                                    disabled={user.isAccountant == "y" ? true : false || (extract.concilied && !extract.isEdittingConcilied)}
                                                                                    hasError={extract.conciliation.validationErrors.supplierOrCustomer}
                                                                                    renderAddButton={(typedText) => <ApiResourceSelectAddButton label="Adicionar Cliente" onClick={() => handleClickAddCustomer(typedText, 'customer', index)} />}
                                                                                />
                                                                        }
                                                                    </div>
                                                                    <div className="col-lg-6">
                                                                        <TextField
                                                                            select
                                                                            label="Forma de Pagamento"
                                                                            SelectProps={{
                                                                                MenuProps: {
                                                                                    className: classes.menu,
                                                                                },
                                                                            }}
                                                                            className={classes.error}
                                                                            margin="normal"
                                                                            variant="outlined"
                                                                            size="small"
                                                                            value={extract.conciliation.payment}
                                                                            onChange={(e) => {
                                                                                var bank = bankStatement.extract;
                                                                                bank[index].conciliation.payment = e.target.value;
                                                                                setBankStatement({ extract: bank});
                                                                            }}
                                                                            disabled={user.isAccountant == "y" ? true : false || (extract.concilied && !extract.isEdittingConcilied)}
                                                                            error={extract.conciliation.validationErrors.payment}
                                                                        >
                                                                            <MenuItem key="0" value="">
                                                                                Selecione
                                                                            </MenuItem>

                                                                            {
                                                                                paymentOptions.map((payment, index) => (
                                                                                    <MenuItem key={index + 1} value={payment.value}>
                                                                                        {payment.value}
                                                                                    </MenuItem>
                                                                                ))
                                                                            }
                                                                        </TextField>
                                                                    </div>
                                                                    <div className="col-lg-6">
                                                                        <ApiResourceSelect
                                                                            label="Centro de Custos"
                                                                            getOptionLabel={(option: CenterCost) => option.name}
                                                                            value={extract.conciliation.centerCost}
                                                                            onSelect={(option) => {
                                                                                var bank = bankStatement.extract;
                                                                                bank[index].conciliation.centerCost = String(option?.id ?? '');
                                                                                setBankStatement({ extract: bank});
                                                                            }}
                                                                            apiSearchHandler={(typedText) => CenterCostService.getCenterCostsFiltered({ name: typedText })}
                                                                            getSelectedOption={(loadedOptions) => {
                                                                                if(!extract.conciliation.centerCost) return null;
                                                                                return loadedOptions.find((option) => option.id === Number(extract.conciliation.centerCost)) ?? CenterCostService.getCenterCostById(extract.conciliation.centerCost)
                                                                            }}
                                                                            disabled={user.isAccountant == "y" ? true : false || (extract.concilied && !extract.isEdittingConcilied)}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </React.Fragment>
                                            )
                                        }
                                    })
                                } 
                            </div>

                        </div>
                    </>
                : <></>
            }
            
        </div>
    );
}
