import React from 'react';
import {
    Table,
    TableRow, TableHead,
    TableBody,
    TableCell,
    Tooltip
} from '@material-ui/core';
import { Button } from 'react-bootstrap';

type solicitationHistory = {
    historyData?: any,
    solicitationCreatedAt?: string,
    setShowViewHistoricModal: React.Dispatch<React.SetStateAction<boolean>>,
    setSelectedHistory: React.Dispatch<React.SetStateAction<any>>,
}

const SolicitationHistory: React.FC<solicitationHistory> = (
    {
        historyData,
        solicitationCreatedAt,
        setShowViewHistoricModal,
        setSelectedHistory,
    }) => {   
    const count = historyData ? historyData.length : 0;
    
    return (
            <Table className="wrap shadow mt-3">
                <TableHead>
                    <TableRow>
                        <TableCell style={{ backgroundColor: "#fafafa" }}>Atividade</TableCell>
                        <TableCell style={{ backgroundColor: "#fafafa" }}>Usuário</TableCell>
                        <TableCell style={{ backgroundColor: "#fafafa" }}>Data inicial</TableCell>
                        <TableCell style={{ backgroundColor: "#fafafa" }}>Data final</TableCell>
                        <TableCell style={{ backgroundColor: "#fafafa" }}>#</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        historyData.map((h: any, index: number) => 
                            <TableRow
                                onClick={() => {
                                    setShowViewHistoricModal(true);
                                    setSelectedHistory(h);
                                }}
                                className="cursor-pointer"
                                style={
                                    count == index + 1 ?
                                        styles.selected
                                        : {}
                                }
                                hover
                            >
                                <TableCell>
                                    {h.solicitationName}
                                    <Tooltip title="As etapas foram puladas">
                                        <i className={'flaticon2-fast-next text-secondary ml-2 ' + (h.skippedSteps === "y" ? "" : "d-none")}></i>
                                    </Tooltip>
                                </TableCell>
                                <TableCell>{h.user_name}</TableCell>
                                <TableCell>{solicitationCreatedAt}</TableCell>
                                <TableCell>{h.end_date}</TableCell>
                                <TableCell>
                                    <Button 
                                        onClick={() => {
                                            setShowViewHistoricModal(true);
                                            setSelectedHistory(h);
                                        }}
                                        size="sm"
                                    >
                                        <i className="flaticon-file-2 p-0"></i>
                                    </Button>
                                </TableCell>
                            </TableRow>
                        )
                    }
                </TableBody>
            </Table>
    );
}

const styles = {
    table: {
        width: "100%",
        border: "1px solid #DCDCDC",
    },
    selected: {
        backgroundColor: "#B3E5FC",
    },
}

export default SolicitationHistory;