import React, { useCallback, useEffect, useState } from 'react';
import { Box, Grid, Paper, TextField } from '@material-ui/core';
import api from '../../../services/Api';
import { Badge, Button, Spinner } from 'react-bootstrap';
import SolicitationFormModal from '../../../components/BpmnSolicitation/SolicitationFormModal';

const SolicitationCategory = () => {
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(0);
    const [selectedCategoryName, setSelectedCategoryName] = useState('Todos');
    const [solicitations, setSolicitations] = useState([]);
    const [search, setSearch] = useState('');
    const [displaySolicitationModal, setDisplaySolicitationModal] = useState(false);
    const [selectedSolicitationId, setSelectedSolicitationId] = useState(0);
    const [selectedProcessId, setSelectedProcessId] = useState(0);
    const [updateList, setUpdateList] = useState(false);

    useEffect(() => {
        const fetchCategories = async() => {
            let { data } = await api.get("/bpm/category/all");
            setCategories(data ?? []);
        }
        fetchCategories().then();

        const fetchSolicitations = async() => {
            let { data } = await api.get(`/bpm/process-solicitation/category/0/search/0`);
            setSolicitations(data ?? []);
        }
        fetchSolicitations().then();
    }, []);

    useEffect(() => {
        const fetchSolicitations = async() => {
            if (updateList) {
                await searchSolicitation();
            }
        }
        fetchSolicitations().then();
    }, [updateList]);

    useEffect(() => {
        if (selectedSolicitationId) {
            setDisplaySolicitationModal(true);
        }
    }, [selectedSolicitationId]);

    useEffect(() => {
        if (!displaySolicitationModal) {
            setSelectedSolicitationId(0);
            setSelectedProcessId(0);
        }
    }, [displaySolicitationModal]);

    const selectCategory = async(id: number, name: string) => {
        setSelectedCategory(id);
        setSelectedCategoryName(name);
        let searchVal = search ? search : '0';
        let url = `/bpm/process-solicitation/category/${id}/search/${searchVal}`;
        let { data } = await api.get(url);
        setSolicitations(data ? data : []);
    }

    const searchSolicitation = async() => {
        let searchVal = search ? search : '0';
        let url = `/bpm/process-solicitation/category/${selectedCategory}/search/${searchVal}`;
        let { data } = await api.get(url);
        setSolicitations(data ? data : []);
    }

    const displayDate = (date: string, type: number = 0) => {
        let dateTimeArr = date.split('T');
        let dateArr = dateTimeArr[0].split('-');
        let formDate = `${dateArr[2]}/${dateArr[1]}/${dateArr[0]}`;
        if (type) {
            formDate = dateTimeArr[0];
        }

        return formDate;
    }

    const getDateStatus = (date: string) => {
        let dateObj = new Date();
        let year = dateObj.getFullYear();
        let m = dateObj.getMonth() + 1;
        let d = dateObj.getDate();
        let month = m > 9 ? m : `0${m}`;
        let day = d > 9 ? d : `0${d}`;
        let today = `${year}-${month}-${day}`;
        let solDate = displayDate(date, 1);
        let status = 'not_late';

        if (today > solDate) {
            status = 'late';
        }

        return status;
    }

    const selectSolicitation = (id: number, processId: number) => {
        setSelectedSolicitationId(id);
        setSelectedProcessId(processId);
    }

    return(
        <div>
            <div style={styles.categoryDiv}>
                <h3>Categorias</h3>
                <div
                    key={0}
                    style={
                        selectedCategory == 0 ?
                            {...styles.categoryList, ...styles.categoryListSelected}
                            :
                            styles.categoryList
                    }
                    onClick={() => selectCategory(0, 'Todos')}
                >
                    <span style={styles.categorySpan}>Todos</span>
                </div>
                {
                    categories.map((m: any) =>
                        <div
                            key={m.id}
                            style={
                                selectedCategory == m.id ?
                                    {...styles.categoryList, ...styles.categoryListSelected}
                                    :
                                    styles.categoryList
                            }
                            onClick={() => selectCategory(m.id, m.name)}
                        >
                            <span style={styles.categorySpan}>{m.name}</span>
                        </div>
                    )
                }
            </div>
            <div style={styles.solicitationCategoryListDiv}>
                <div style={styles.categoryTitleDiv}>
                    <h3>{ selectedCategoryName }</h3>
                    <TextField
                        variant={"standard"}
                        margin={"none"}
                        placeholder={"Pesquise pela solicitação aqui..."}
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        style={styles.searchInput}
                        InputProps={{
                            disableUnderline: true,
                        }}
                    />
                    <Button
                        style={styles.searchButton}
                        onClick={() => searchSolicitation()}
                    >
                        <i className={"flaticon-search"}></i>
                    </Button>
                </div>
                <div style={styles.solicitationDiv}>
                    {
                        solicitations.map((s: any) =>
                            <div
                                style={styles.solicitationList}
                                onClick={() => selectSolicitation(s.id, s.ProcessBpmId)}
                                title={s.name}
                            >
                                <div>
                                    <h6>
                                        {
                                            s.name ?
                                                s.name.length > 23 ?
                                                    `${s.name.substring(0, 20)}...`
                                                    :
                                                    s.name
                                                : 'N/A'
                                        }
                                    </h6>
                                </div>
                                <div>
                                    <small style={{marginTop: 10, float: 'left'}}>
                                        Prazo: {displayDate(s.endDate)}
                                    </small>&nbsp;
                                    <div style={{float: 'right'}}>
                                        {
                                            getDateStatus(s.endDate) == 'late' ?
                                                <div
                                                    style={{
                                                        ...styles.solicitationDateStatus,
                                                        ...styles.solicitationDateStatusLate
                                                    }}
                                                >Atrasado</div>
                                                :
                                                <div
                                                    style={{
                                                        ...styles.solicitationDateStatus,
                                                        ...styles.solicitationDateStatusNotLate
                                                    }}
                                                >No Prazo</div>
                                        }
                                    </div>
                                </div>
                                <hr />
                                <div>
                                    <small>
                                        Processo: <br/> 
                                        {s.process.name.length > 30 ? `${s.process.name.substring(0, 27)}...` : s.process.name}
                                    </small>
                                </div>
                                <hr />
                                <div>
                                    <small>
                                        Categoria: {s.category ? s.category.name : 'N/A'}
                                    </small>
                                </div>
                            </div>
                        )
                    }
                </div>
            </div>
            <SolicitationFormModal
                displayModal={displaySolicitationModal}
                setDisplayModal={setDisplaySolicitationModal}
                selectedSolicitationId={selectedSolicitationId}
                selectedProcessId={selectedProcessId}
                setUpdateList={setUpdateList}
            />
        </div>
    );
}

const styles = {
    categoryDiv: {
        border: '1px solid #EFEFEF',
        borderRadius: 5,
        padding: 5,
        width: '19%',
        float: 'left' as 'left',
        textAlign: 'center' as 'center',
        boxShadow: '6px 7px 13px #BBB',
    },
    categoryList: {
        padding: 5,
        marginTop: 3,
        width: '100%',
        color: '#1A237E',
        cursor: 'pointer' as 'pointer',
    },
    categoryListSelected: {
        color: '#FFF',
        backgroundColor: '#1A237E',
        borderRadius: 5,
    },
    categorySpan: {
        fontWeight: 420,
        fontSize: 15,
    },
    solicitationCategoryListDiv: {
        border: '1px solid #EFEFEF',
        borderRadius: 5,
        padding: 5,
        width: '79%',
        float: 'right' as 'right',
        boxShadow: '6px 7px 13px #BBB',
    },
    categoryTitleDiv: {
        padding: 5,
        width: '100%',
        textAlign: 'center' as 'center',
        marginBottom: 15,
    },
    solicitationDiv: {
        padding: 5,
        width: '100%',
    },
    solicitationList: {
        border: '1px solid #EFEFEF',
        borderRadius: 5,
        margin: '1%',
        width: '23%',
        float: 'left' as 'left',
        padding: 15,
        cursor: 'pointer' as 'pointer',
        boxShadow: '3px 0 10px #DDD',
    },
    searchInput: {
        borderRadius: '.42rem 0 0 .42rem',
        border: '1px solid #DCDCDC',
        width: 'calc(100% - 52px)',
        backgroundColor: '#FFF',
        height: 52,
        padding: 12,
    },
    searchButton: {
        height: 52,
        width: 52,
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
    },
    solicitationDateStatus: {
        padding: 6,
        borderRadius: 5,
        color: '#FFF',
        textAlign: 'center' as 'center',
    },
    solicitationDateStatusLate: {
        backgroundColor: 'red',
    },
    solicitationDateStatusNotLate: {
        backgroundColor: 'green',
    },
}

export default SolicitationCategory;