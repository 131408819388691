import React from 'react';
import {useSubheader} from '../../_metronic/layout';
import { Route, Switch } from 'react-router-dom';
import { ListSolicitation } from '../pages/Bpm/Solicitation/List-Solicitation';
import { SolicitationProcessForm } from '../pages/Bpm/SolicitationProcessForm/SolicitationProcessForm';

export function Bpm() {
    const useSubHeader = useSubheader();
    useSubHeader.setTitle("BPM");

    return (
        <Switch>
            <Route path="/bpm/solicitacoes" component={ListSolicitation} />
            <Route path="/bpm/solicitacao-processo-formulario/:processId" component={SolicitationProcessForm}/>
        </Switch>
    );
}
