import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { TextField } from '@material-ui/core';
import api from '../services/Api';

type categoryModal = {
    display: boolean,
    setDisplay: (val:boolean) => void,
    updateCategoryList?: (val: any) => void,
    setCategory?: (val: number) => void,
};

const CategoryModal: React.FC<categoryModal> = ({
    display,
    setDisplay,
    updateCategoryList = null,
    setCategory = null,
}) => {
    const [showModal, setShowModal] = useState(false);
    const [categoryName, setCategoryName] = useState('');
    const [responseMessage, setResponseMessage] = useState('');

    useEffect(() => {
        setShowModal(display);
        closeModal(display);
    }, [display]);

    useEffect(() => {
        setDisplay(showModal);
        closeModal(showModal);
    }, [showModal]);

    const saveCategory = async() => {
        if (categoryName) {
            let { data } = await api.post('/bpm/category/save', { categoryName });
            if (data) {
                setResponseMessage('Categoria salva com sucesso');
                setCategoryName('');

                if (updateCategoryList) {
                    updateCategoryList(data.allCategories);
                }

                if (setCategory) {
                    setCategory(data.resp.id);
                }
            }            
        }
    }

    const closeModal = (val: boolean) => {
        if (!val) {
            setCategoryName('');
            setResponseMessage('');
        }
    }

    return (
        <Modal
            centered
            aria-labelledby="contained-modal-warning"

            show={showModal}
            onHide={() => {setShowModal(false); closeModal(false);}}
        >
            <Modal.Header closeButton>
                <Modal.Title className="d-flex align-items-center">
                    Criar nova categoria
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <strong>Digite aqui o nome da nova categoria</strong>
                <TextField
                    label="Categoria"
                    multiline
                    rows="1"
                    margin="normal"
                    variant="outlined"
                    size="small"
                    inputProps={{ maxLength: 50 }}
                    value={categoryName}
                    onChange={(e) => setCategoryName(e.target.value)}
                />
                <small style={{color: 'red'}}>{ responseMessage }</small>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={()=> saveCategory()}>Salvar</Button>
                <Button variant="secondary" onClick={() => setShowModal(false)}>Cancelar</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default CategoryModal;