import React, { useState, useEffect, useRef } from 'react';
import { Grid, MenuItem, TextField, Select, FormControl, InputLabel, SvgIcon, Popover, Typography, Checkbox, FormControlLabel} from '@material-ui/core';
import { Button, Spinner, Tab, Tabs, Table, Modal } from 'react-bootstrap';
import DestinationField from './DestinationField';
import BpmnModalForm from './BpmnModalForm';

interface Responsible{
    id: string;
    firstname: string;
    lastname?: string;
}

interface Employee{
    id: string;
    firstname: string;
    lastname?: string;
}

interface FileItem {
    id: number,
    file: string,
    fileObject: File,
    saved: boolean
}

type bpmnModal = {
    displayModal: boolean,
    shape: any,
    setShape?: (val: any) => void,
    tableData: any,
    setTableData: (val: any) => void,
    employees: any,
    groups: any,
    setGroups: (val: any) => void;
    removeGroups?: number[];
    setRemoveGroups?: React.Dispatch<React.SetStateAction<number[]>>;
    removeFields?: number[];
    setRemoveFields?: React.Dispatch<React.SetStateAction<number[]>>;
    removeTables?: number[];
    setRemoveTables?: React.Dispatch<React.SetStateAction<number[]>>
}

const BpmnModal: React.FC<bpmnModal> = ({
    displayModal,
    shape,
    setShape,
    tableData,
    setTableData,
    employees = [],
    groups,
    setGroups,
    removeGroups,
    setRemoveGroups,
    removeFields,
    setRemoveFields,
    removeTables,
    setRemoveTables
}) => {
    const [showModal, setShowModal] = useState(false);
    const [activityTab, setActivityTab] = useState("form");
    const [atividadeId, setAtividadeId] = useState('');
    const [tipo, setTipo] = useState<string>('Atividade');
    const [atividade, setAtividade] = useState<string>('');
    const [descricaoAtividade, setDescricaoAtividade] = useState<string>('');
    const [aprovacaoSupervisor, setAprovacaoSupervisor] = useState<boolean>(false);
    const [responsaveis, setResponsaveis] = useState<Responsible[]>([]);
    const [prazo, setPrazo] = useState<number>(0);
    const employeesOptions = employees.map((employee: any) => {
        return employee.lastname ? employee.firstname + " " + employee.lastname : employee.firstname;
    });
    const [destino, setDestino] = useState<string[]>([]);

    const [files, setFiles] = useState<FileItem[]>([]);
    const [fileId, setFileId] = useState<number>(1);
    const fileInputRef = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        setShowModal(displayModal);
    }, [displayModal]);

    useEffect(() => {
        setAtividade(shape ? shape.atividade : '');
        setTipo(shape ? shape.tipo : 'Atividade');
        setAtividadeId(shape ? shape.id : '');
        setDescricaoAtividade(shape ? shape.descricao : '');
        setActivityTab('form');
        setDestino(shape ? shape.destino : []);
        setPrazo(shape ? shape.prazo : 0);
        setResponsaveis(shape ? shape.responsaveis : []);
        setAprovacaoSupervisor(shape ? shape.aprovacaoSupervisor : false);
        setFiles(shape ? shape.anexos : '');

        if (shape?.tipo == 'Exclusivo' || shape?.tipo == 'Fim') {
            setActivityTab('general');
        }

    }, [shape]);

    const closeModal = () => {
        setShowModal(false);
        if (setShape) {
            setShape(null);
        }
    }

    const handleTipoChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setTipo(event.target.value as string);
    };

    const isTipoInArray = (tipo: string): boolean => {
        return tableData.some((item: any) => item.tipo === tipo);
    };

    const handleResponseChange = (value: string, index: number) => {
        const employee = employees.find((emp: any) => {
            return emp.lastname ? (emp.firstname + " " + emp.lastname) === value : emp.firstname === value;
        });
        if (employee) {
            const newResponsible = [...responsaveis];
            newResponsible[index] = { ...newResponsible[index], firstname: employee.firstname, lastname: employee.lastname ?? undefined, id: employee.id };
            setResponsaveis(newResponsible);
        }
    };

    const handleDeleteResponseField = (index: number) => {
        const newResponse = [...responsaveis];
        newResponse.splice(index, 1);
        setResponsaveis(newResponse);
    };

    const onSubmit = () => {
        let count = tableData.length;
        let data = tableData;
        for (let i = 0; i < count; i++) {
            if (atividadeId == tableData[i].id) {
                data[i].atividade = atividade;
                data[i].tipo = tipo;
                data[i].descricao = descricaoAtividade;
                data[i].destino = destino;
                data[i].prazo = prazo;
                data[i].responsaveis = responsaveis;
                data[i].aprovacaoSupervisor = aprovacaoSupervisor;
                data[i].anexos = files;
            }
        }
        setTableData(data);
        closeModal();
    }

    const addFile = () => {
        fileInputRef.current?.click();
    }

    const deleteFile = (id: number) => {
        setFiles((prevFiles) => prevFiles.filter((prevFile) => prevFile.id !== id));
    }

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            const newFile = event.target.files[0];
            const newFileItem: FileItem = { id: fileId, file: newFile.name, fileObject: newFile, saved: false };
            setFiles((prevFiles) => {
                return Array.isArray(prevFiles) ? [...prevFiles, newFileItem] : [newFileItem];
            })
            setFileId((prevId) => prevId + 1);
        }
    }

    return(
        <Modal
            show={showModal}
            onHide={() => closeModal()}
            aria-labelledby="contained-modal-warning"
            backdrop={false}
            size={"lg"}
            style={styles.modalStyle}
        >
            <Modal.Header closeButton>
                <Modal.Title className="d-flex align-items-center">
                    <i className="flaticon2-writing icon-xl text-secondary mr-3"></i>
                    {shape ? shape.atividade : ''}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{width: 600}}>
                <Tabs
                    activeKey={activityTab}
                    onSelect={(tab: string) => setActivityTab(tab)}
                    id="newproduct-form-tabs"
                    className="mb-5"
                >
                    {
                        tipo != 'Exclusivo' && tipo != 'Fim' ?
                            <Tab eventKey="form" title="Formulário">
                                <BpmnModalForm
                                    groups={groups}
                                    setGroups={setGroups}
                                    processId={atividadeId}
                                    formId={0}
                                    removeGroups={removeGroups}
                                    setRemoveGroups={setRemoveGroups}
                                    removeFields={removeFields}
                                    setRemoveFields={setRemoveFields}
                                    removeTables={removeTables}
                                    setRemoveTables={setRemoveTables}
                                />
                            </Tab>
                            : <></>
                    }
                    <Tab eventKey="general" title="Geral">
                        {
                            tipo != 'Exclusivo' ?
                                <FormControl variant="outlined" size="small" margin="normal">
                                    <InputLabel>Tipo de atividade</InputLabel>
                                    <Select
                                        value={tipo}
                                        onChange={handleTipoChange}
                                        label="Tipo de atividade"
                                    >
                                        <MenuItem value="Início" disabled={isTipoInArray('Início')}>Início</MenuItem>
                                        <MenuItem value="Atividade">Atividade</MenuItem>
                                        <MenuItem value="Fim" disabled={isTipoInArray('Fim')}>Fim</MenuItem>
                                    </Select>
                                </FormControl>
                                : <></>
                        }
                        <TextField
                            type="string"
                            label="Nome da atividade"
                            size="small"
                            margin="normal"
                            variant="outlined"
                            value={atividade}
                            onChange={(e) => setAtividade(e.target.value)}
                        />
                        {
                            tipo != 'Exclusivo' && tipo != 'Fim' ?
                                <>
                                    <TextField
                                        multiline
                                        rows={3}
                                        type="string"
                                        label="Descrição da atividade"
                                        size="small"
                                        margin="normal"
                                        variant="outlined"
                                        value={descricaoAtividade}
                                        onChange={(e) => setDescricaoAtividade(e.target.value)}
                                    />
                                    <div style={{display: "inline-flex", width: "100%"}}>
                                        <div style={{width: "76%"}}>
                                            <DestinationField
                                                value={responsaveis.length ? `${responsaveis[0].firstname} ${responsaveis[0]?.lastname}` : '' }
                                                options={employeesOptions.filter(Boolean) as string[]}
                                                onChange={handleResponseChange}
                                                onDelete={handleDeleteResponseField}
                                                index={0}
                                                title="Responsável"
                                            />
                                        </div>
                                        <div style={{width: "20%", marginLeft: "4%"}}>
                                            <TextField
                                                type="number"
                                                label="Prazo (dias)"
                                                size="small"
                                                margin="normal"
                                                variant="outlined"
                                                value={prazo}
                                                InputProps={{
                                                    inputProps: { min: 0 }
                                                }}
                                                onChange={(e) => setPrazo(Number(e.target.value) < 0 ? 0 : Number(e.target.value))}
                                            />
                                        </div>
                                    </div>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={aprovacaoSupervisor}
                                                onChange={(e) => setAprovacaoSupervisor(e.target.checked)}
                                                color="primary"
                                            />
                                        }
                                        label="Precisa de aprovação do supervisor?"
                                    />
                                </>
                                : <></>
                        }
                    </Tab>

                    <Tab eventKey="files" title="Anexos">
                        <Button variant="primary" onClick={addFile}>
                            Anexar arquivo
                        </Button>

                        <input
                            type="file"
                            className="d-none"
                            ref={fileInputRef}
                            onChange={handleFileChange}
                        />

                        {
                            files && files.length > 0 && (
                                <div className="mt-3">
                                    <ul className="d-flex flex-column" style={{ gap: "1rem" }}>
                                        {files.map((file: FileItem) => (
                                            <li key={file.id}>
                                                {file.file}
                                                <Button className="ml-2" size="sm" variant="danger" onClick={() => deleteFile(file.id)}>
                                                    <i className="flaticon-delete p-0"/>
                                                </Button>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            )
                        }
                    </Tab>
                </Tabs>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={() => onSubmit()}>Salvar</Button>
                <Button variant="secondary" onClick={() => closeModal()}>Cancelar</Button>
            </Modal.Footer>
        </Modal>
    );
}

const styles = {
    modalStyle: {
        height: 'auto',
        width: 'auto',
        margin: '5% 0 0 60%',
        border: 1,
        borderColor: "red",
    }
}

export default BpmnModal;
