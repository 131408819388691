import React from 'react';
import { FormControl, InputLabel, Select, MenuItem, IconButton } from '@material-ui/core';
import { Delete as DeleteIcon } from '@material-ui/icons';

interface DestinationFieldProps {
    value: string;
    options: string[];
    onChange: (value: string, index: number) => void;
    onDelete?: (index: number) => void;
    index: number;
    selectedActivity?: string;
    title?: string;
}

const DestinationField: React.FC<DestinationFieldProps> = ({
    value,
    options,
    onChange,
    onDelete,
    index,
    selectedActivity = "",
    title = "Destino",
}) => {
    return (
        <div className="d-flex align-items-center mb-2">
            <FormControl variant="outlined" size="small" margin="normal" style={{ flex: 1 }}>
                <InputLabel>{title}</InputLabel>
                <Select
                    value={value}
                    onChange={(e) => onChange(e.target.value as string, index)}
                    label="Destino"
                >
                    {options.map((option, i) =>
                        selectedActivity != option ?
                            <MenuItem key={i} value={option}>{option}</MenuItem>
                            : <MenuItem key={i} className={"d-none"}></MenuItem>
                    )}
                </Select>
            </FormControl>
            {/* <IconButton onClick={() => onDelete(index)} color="secondary" aria-label="delete">
                <DeleteIcon />
            </IconButton> */}
        </div>
    );
};

export default DestinationField;
