import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import {
    Tooltip,
    TextField,
    MenuItem,
    Grid,
    FormControlLabel,
    Checkbox,
    FormControl,
    FormLabel,
    RadioGroup,
    Radio,
} from '@material-ui/core';
import api from '../../services/Api';
import { Badge, Button, Form, Modal, Spinner, Tab, Tabs, Toast } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import useBackendLoad from '../../hooks/backendReload';
import { ProcessBpm } from '../../types/ProcessBpm';
import { LoadDataParams } from '../../components/ListWithModalChangeSituation';
import { ActivityProcessBpm } from '../../types/ActivityProcessBpm';
import ModalError from '../../components/ModalError';
import ModalSuccess from '../../components/ModalSuccess';
import NumberFormat from 'react-number-format';
import { getDate } from '../../utils/dateTimeHelper';
import BpmnWithFormViewer from '../../components/BpmnWithFormViewer';
import SolicitationHistory from '../../components/BpmnSolicitation/SolicitationHistory';
import axios from 'axios';

type Filters = {
    searchQuery: string;
    startDate: string;
    endDate: string;
    processFilter: string;
    situation: string;
    requester: string;
    responsible: string;
}

interface FileItem {
    id: number,
    file: string,
}

type solicitationFormModal = {
    displayModal: boolean,
    setDisplayModal: (val: boolean) => void,
    selectedSolicitationId: number,
    selectedProcessId: number,
    setUpdateList?: (val: boolean) => void
}

const SolicitationFormModal: React.FC<solicitationFormModal> = ({
    displayModal,
    setDisplayModal,
    selectedSolicitationId,
    selectedProcessId,
    setUpdateList,

}) => {
    const { user } = useSelector((state: any) => state.auth);

    const [solicitationId, setSolicitationId] = useState<number>(0);
    const [processId, setProcessId] = useState<number>(0);
    const [activities, setActivities] = useState<any>([]);
    const [activitiesCount, setActivitiesCount] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [requester, setRequester] = useState<string>("");

    const filtersRef = useRef<Filters | null>(null);
    const [pagesActivity, setPagesActivity] = useState(0);
    const [rowsPerPageActivity, setRowsPerPageActivity] = useState(10);
    const [sortDirectionActivity, setSortDirectionActivity] = useState<'ASC' | 'DESC'>('DESC');
    const [sortReferenceActivity, setSortReferenceActivity] = useState('id');
    const [pageChanged, setPageChanged] = useState(true);
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [isLoadingSolicitationForm, setIsLoadingSolicitationForm] = useState<boolean>(false);
    const {triggerLoad, setTriggerLoad, reloadData} = useBackendLoad();

    const [showNewSolicitationModal, setShowNewSolicitationModal] = useState<boolean>(false);
    const [showViewSolicitationModal, setShowViewSolicitationModal] = useState<boolean>(false);
    const [showViewHistoricModal, setShowViewHistoricModal] = useState<boolean>(false);
    const [solicitationName, setSolicitationName] = useState<string>("");
    const [solicitationProcessId, setSolicitationProcessId] = useState<any>(0);
    const [processes, setProcesses] = useState<ProcessBpm[]>();

    const [selectedHistory, setSelectedHistory] = useState<any>([]);

    const [showModalError, setShowModalError] = useState<boolean>(false);
    const [msgError, setMsgError] = useState<string>("");
    const [showModalSuccess, setShowModalSuccess] = useState<boolean>(false);
    const [msgModal, setMsgModal] = useState<string>("");
    const [isSubmit, setIsSubmit] = useState<boolean>(false);
    const [isSubmitSolicitationForm, setIsSubmitSolicitationForm] = useState<boolean>(false);

    const [name, setName] = useState<string>("");
    const [value, setValue] = useState<any>([]);
    const [form, setForm] = useState<any>([]);
    const [solicitationProcess, setSolicitationProcess] = useState<any>([]);
    const [solicitationHistory, setSolicitationHistory] = useState<any>([]);
    const [solicitationAttachments, setSolicitationAttachments] = useState<FileItem[]>([]);
    const [currentStage, setCurrentStage] = useState<string>("");
    const [currentStageType, setCurrentStageType] = useState<number>(1);
    const [requesterName, setRequesterName] = useState("");
    const [endDateActivity, setEndDateActivity] = useState("");
    const [solicitationCreatedAt, setSolicitationCreatedAt] = useState("");
    const [statusSolicitation, setStatusSolicitation] = useState("");
    const [processName, setProcessName] = useState("");
    const [supervisorsName, setSupervisorsName] = useState("");
    const [supervisors, setSupervisors] = useState<any>([]);
    const [responsible, setResponsible] = useState<any>([]);
    const [logUserGroupPermition, setlogUserGroupPermition] = useState<any>([]);
    const [hideTabs, setHideTabs] = useState<number[]>([]);
    const [error, setError] = useState<any>(undefined);
    const [b, setB] = useState<boolean>(true);
    const [stagePermitions, setStagePermitions] = useState<any>([]);
    const [selectedSolicitationGroup, setSelectedSolicitationGroup] = useState<any>({});
    const [advanceToNextActivity, setAdvanceToNextActivity] = useState<boolean>(false);
    const [destinationOptions, setDestinationOptions] = useState<any>({});
    const [selectedNextStage, setSelectedNextStage] = useState<string>("");
    const [formErrors, setFormErrors] = useState<any>([]);
    const [formTableErrors, setFormTableErrors] = useState<any>([]);
    const [isDeletingColumn, setIsDeletingColumn] = useState<boolean>(false);
    const permitionsGroup = ["Editar", "Não visualizar"];
    const processActivities: any = ["Início", "Fim", "Atividade", "Exclusivo", "Paralelo", "Inclusivo"];

    const [activeTab, setActiveTab] = useState<string>("form");
    const [selectedShape, setSelectedShape] = useState<any>(null);

    const [checkedActivity, setCheckedActivity] = useState<string>("");
    const [checkedSteps, setCheckedSteps] = useState<any>([]);
    const [disabledSteps, setDisabledSteps] = useState<any>([]);

    let showButton = false;
    let requiredFields: any = [];
    let requiredTableFields: any = [];

    useEffect(() => {
        setShowViewSolicitationModal(displayModal);
    }, [displayModal]);

    useEffect(() => {
        setDisplayModal(showViewSolicitationModal);
    }, [showViewSolicitationModal]);

    useEffect(() => {
        setSolicitationId(selectedSolicitationId);
        setProcessId(selectedProcessId);
        setSortReferenceActivity(String(selectedSolicitationId));
        if (selectedSolicitationId && selectedProcessId) {
            getSolicitation(selectedProcessId, selectedSolicitationId);
        }
    }, [selectedSolicitationId, selectedProcessId]);

    const getSolicitation = useCallback((processId: number, solicitationId: number) => {
        setShowViewSolicitationModal(true);
        setIsLoadingSolicitationForm(true);

        const fetch = async() => {
            try {
                if (Number(solicitationId)) {
                    let resp = await api.get(`/bpm/displaySolicitation/${solicitationId}`);
                    let data = resp.data;
                    let sol = data.solicitation;
                    if (sol) {
                        setSolicitationId(solicitationId);
                        setProcessId(processId);
                        setName(sol.name);
                        setValue(JSON.parse(sol.solicitationData));
                        let formArr = JSON.parse(sol.formData);
                        setForm(formArr);
                        let procArr = JSON.parse(sol.processData);
                        setSolicitationProcess(procArr);
                        setSolicitationHistory(data.history);
                        setSolicitationAttachments(data.processAttachments);
                        setCurrentStage(sol.processBpmStage);
                        setCurrentStageType(data.processType);
                        setRequester(sol.RequesterId);
                        setRequesterName(sol.requester);
                        setEndDateActivity(sol.endDate);
                        setSolicitationCreatedAt(sol.solicitation_created_at);
                        setStatusSolicitation(sol.statusSolicitation);
                        setProcessName(data.processName);

                        let destinationProcessArr: any = [];
                        let destinationProcess: any = [];
                        let cSteps = checkedSteps;
                        let dSteps = disabledSteps;

                        procArr.forEach((process: any, index: number) => {
                            if (process.id === sol.processBpmStage) {
                                setSelectedSolicitationGroup(process);
                                if (process.tipo === "Exclusivo") {
                                    destinationProcessArr = (process.destino);
                                    setAdvanceToNextActivity(true);
                                } else {
                                    setAdvanceToNextActivity(false);
                                }
                            }

                            cSteps.push(process.tipo === "Início" ? true : process.id === sol.processBpmStage ? true : false);
                            dSteps.push(process.tipo === "Início" ? true : false);
                        });

                        let procArrClone = procArr.toReversed();
                        let indexProcessId = 0;

                        cSteps.reverse();
                        dSteps.reverse();
                        
                        procArrClone.forEach((proc: any, index: number) => {
                            if (sol.processBpmStage === proc.id) {
                                indexProcessId = index;
                            }
                        });

                        cSteps.forEach((cStep: any, index: number) => {
                            if (index < indexProcessId) {
                                cSteps[index] = true;
                                dSteps[index] = true;
                            }
                        });

                        setCheckedSteps(cSteps);
                        setDisabledSteps(dSteps);

                        destinationProcessArr.forEach((option: any) => {
                            procArr.forEach((process: any) => {
                                if (option === process.id) {
                                    destinationProcess.push({
                                        value: process.id,
                                        name: process.atividade
                                    });
                                }
                            })
                        })

                        setDestinationOptions(destinationProcess);

                        const supervisorsNameConverted = sol.process_supervisors ? JSON.parse(sol.process_supervisors).map((supervisorName: any, index: number) => index == 0 ? `${supervisorName.firstname} ${supervisorName.lastname}` : `${supervisorName.firstname} ${supervisorName.lastname}`) : "";
                        setSupervisorsName(supervisorsNameConverted);

                        let superv = sol.process_supervisors ? JSON.parse(sol.process_supervisors) : [];
                        let supCount = superv.length;
                        let supArr = [];
                        if (supCount) {
                            for (let i = 0; i < supCount; i++) {
                                supArr.push(superv[i].id);
                            }
                        }
                        setSupervisors(supArr);

                        let procCount = procArr.length;
                        let respDataArr = [];
                        let permitions: any = {};
                        for (let i = 0; i < procCount; i++) {
                            if (procArr[i].id == sol.processBpmStage) {
                                respDataArr = procArr[i].responsaveis;
                                permitions = procArr[i].permissions ? procArr[i].permissions : {};
                            }
                        }
                        let respArr = [];
                        let rspCount = respDataArr.length;
                        for (let i = 0; i < rspCount; i++) {
                            respArr.push(respDataArr[i].id);
                        }
                        setResponsible(respArr);

                        let logPermition = [];
                        let permitionsArr: any = [];
                        Object.keys(permitions).map((index: any) => {
                            permitionsArr.push(permitions[index]);
                        });

                        setStagePermitions(permitionsArr);
                        if (respArr.includes(user.id)) {
                            let formCount = formArr.length;
                            for (let i = 0; i < formCount; i++) {
                                if (permitionsArr[i]) {
                                    logPermition.push(permitionsArr[i]);
                                } else {
                                    logPermition.push("Não visualizar");
                                }
                            }
                        }
                        setlogUserGroupPermition(logPermition);

                        verifyAdvanceButton();
                    }
                } else {
                    if (Number(processId)) {
                        let resp = await api.get(`/bpm/form/displayProcessForm/${processId}`);
                        let data = resp.data;
                        setName(data.form[0].name);
                        setSolicitationProcess(JSON.parse(data.process_data));
                        let groupArr = [];
                        let groupData = data.groups;
                        let gCount = groupData.length;
                        let val = value;

                        let usPermition = [];
                        for (let i = 0; i < gCount; i++) {
                            let group = groupData[i];
                            groupArr.push({
                                name: group.name,
                                index: i,
                                savedId: group.id,
                                inputs: [],
                                tables: [],
                            });
                            val.push({
                                inputs: [],
                                tables: [],
                            });

                            usPermition.push("Editar");
                        }
                        setlogUserGroupPermition(usPermition);
                        setResponsible([user.id]);
                        setSupervisors([]);
        
                        let fieldData = data.fields;
                        let fCount = fieldData.length;
                        let tableData = data.tables;
                        let tCount = tableData.length;
                        let tableFieldData = data.tableFields;
                        let tfCount = tableFieldData.length;
                        for (let i = 0; i < gCount; i++) {
                            let groupArrI =  groupArr[i];
                            let group = groupData[i];
        
                            let input: any = [];
                            let fCounter = 0;
                            let vInput = [];
                            for (let j = 0; j < fCount; j++) {
                                let field = fieldData[j];
                                if (field.groupFormId == group.id) {
                                    let option = field.options.split("*|||*");
                                    let required = field.isRequired == 'y' ? true : false;
                                    let extension = field.extensions;
                                    input.push({
                                        id: fCounter,
                                        name: field.textLabel,
                                        type: field.type,
                                        size: field.length,
                                        rows: 3,
                                        columns: field.lengthColumn,
                                        mask: field.mask,
                                        option,
                                        extension,
                                        required,
                                        savedId: field.id,
                                    })
                                    fCounter++;
                                    vInput.push(field.type == "Caixa_verificacao" ? false : null);
                                }
                            }
                            groupArrI.inputs = input;
                            val[i].inputs = vInput;
        
                            let tables: any = [];
                            let stCounter = 0;
                            let tInput = [];
                            for (let k = 0; k < tCount; k++) {
                                let table = tableData[k];
                                if (table.groupFormId == group.id) {
                                    tables.push({
                                        id: stCounter,
                                        name: table.name,
                                        dataColumns: [],
                                        columns: 0,
                                        rows: table.countRows,
                                        savedId: table.id,
                                    });
        
                                    let dataColumns: any = [];
                                    let dColumnsCounter = 0;
                                    let tfInput: any = [[]];
                                    for (let l = 0; l < tfCount; l++) {
                                        let tField = tableFieldData[l];
                                        if (tField.TableFieldBpmId == table.id) {
                                            let option = tField.options.split("*|||*");
                                            let required = tField.isRequired == 'y' ? true : false;
                                            let extension = tField.extensions;
                                            dataColumns.push({
                                                id: dColumnsCounter,
                                                name: tField.textLabel,
                                                type: tField.type,
                                                size: tField.length,
                                                rows: 3,
                                                columns: tField.lengthColumn,
                                                mask: tField.mask,
                                                option,
                                                extension,
                                                required,
                                                savedId: tField.id,
                                            });
                                            dColumnsCounter++;
                                            tfInput[0].push(tField.type == "Caixa_verificacao" ? false : null);
                                        }
                                    }
                                    tInput.push(tfInput);
                                    tables[stCounter].dataColumns = dataColumns;
                                    stCounter++;
                                }
                            }
                            val[i].tables = tInput;
                            groupArrI.tables = tables;
                        }
                        setValue(val);
                        setForm(groupArr);
                    }
                }

                setIsLoadingSolicitationForm(false);
            } catch (error) {
                console.log(error);
            }
        }
        fetch().then();
    }, [
        showViewSolicitationModal,
        isLoadingSolicitationForm,
        name,
        value,
        form,
        solicitationProcess,
        solicitationHistory,
        currentStage,
        currentStageType,
        requester,
        requesterName,
        endDateActivity,
        solicitationCreatedAt,
        statusSolicitation,
        processName,
        selectedSolicitationGroup,
        supervisorsName,
        supervisors,
        responsible,
        stagePermitions,
        logUserGroupPermition
    ]);

    const addColumn = (groupIndex: number, tableIndex: number) => {
        setError(undefined);
        let numFields = form[groupIndex].tables[tableIndex].dataColumns.length;
        let data = [];
        for (let i = 0; i < numFields; i++) {
            data.push(null);
        }
        let val = value;
        val[groupIndex].tables[tableIndex].push(data);
        setValue(val);
        setUpdate();
    }

    const setUpdate = () => {
        setB(!b);
    }

    const closeSolicitationModal = () => {
        setShowViewSolicitationModal(false);
        setFormErrors([]);
        setFormTableErrors([]);
        setActiveTab("form");
    }

    const downloadFile = async (filename: string) => {
        try {
            const response = await axios.get(`/bpm/solicitation/downloadFile/${filename}`, {
                responseType: "blob",
            });
            const blob = new Blob([response.data]);
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.setAttribute('download', filename);
            link.click();
            link.remove();
        } catch (error) {
            console.log(error);
        }
    }

    const displayTables = (groupIndex: number, tables: any = [], condition: boolean) => {
        return(
            tables.map((t: any, tableIndex: number) =>
                <div key={tableIndex} style={{marginTop: 35, width: "100%"}}>
                    <div className="d-flex flex-row align-items-center justify-content-between col-12">
                        <b style={{color: condition ? "#ccc" : "#555"}}>{t.name}</b>
                        <Tooltip title={condition ? 'Você não possui permissão para editar' : ''}>
                            <Button
                                onClick={() => addColumn(groupIndex, tableIndex)}
                                disabled={condition}
                                className="d-flex flex-row align-items-center"
                                size="sm"
                                variant={condition ? "light" : "primary"}
                            >
                                <i className="flaticon2-plus p-0 mr-3" style={{fontSize: "12px"}} />
                                Adicionar
                            </Button>
                        </Tooltip>
                    </div>
                    <Grid item xs={12}>
                        { displayTableField(groupIndex, tableIndex) }
                    </Grid>
                </div>
            )
        );
    }

    const deleteColumn = async(groupIndex: number, tableIndex: number, columnIndex: number) => {
        setIsDeletingColumn(true);

        try {
            setError(undefined);
            let val = value;
            let columnFields = val[groupIndex].tables[tableIndex][columnIndex];
            let columnFieldslength = columnFields.length;
            let updateColumn = false;
            for (let i = 0; i < columnFieldslength; i++) {
                let fieldType = form[groupIndex].tables[tableIndex].dataColumns[i].type;
                if (fieldType == "Anexo" && columnFields[i]) {
                    updateColumn = true;
                    let filename = columnFields[i];
                    await api.delete('/file', {data: { filename }})
                }
            }

            val[groupIndex].tables[tableIndex].splice(columnIndex, 1);
            let obj = {
                solicitationId,
                value: JSON.stringify(val),
                groupIndex,
                tableIndex,
            }
            let tableRes = await api.post("/bpm/solicitation/updateTable", obj);
            let tableData = JSON.parse(tableRes.data.solicitationData);
            setValue(tableData);
            setUpdate();
        } catch (error) {
            console.log(error);
        }

        setIsDeletingColumn(false);
    }

    const displayTableField = (groupIndex: number, tableIndex: number) => {
        let dataColumns = form[groupIndex].tables[tableIndex].dataColumns;
        let valueTable = value[groupIndex].tables[tableIndex];

        return(
            valueTable.map((c: any, columnIndex: number) =>
                <div className="col-12" key={columnIndex}>
                    { /*<TableCell>{columnIndex + 1}</TableCell>*/ }
                    <div className='row'>
                        {
                            c.map((f: any, fieldIndex: number) => {
                                let type = dataColumns[fieldIndex].type;
                                let placeholder = dataColumns[fieldIndex].name;
                                let rows = dataColumns[fieldIndex].rows;
                                return(
                                    <div className="col" key={fieldIndex}>
                                        { tableFieldType(type, groupIndex, tableIndex, columnIndex, fieldIndex, placeholder, rows) }
                                    </div>
                                );
                            })
                        }
                            {
                                columnIndex ?
                                    <div className="col-1 pt-5">
                                        <Button
                                            variant={"danger"}
                                            onClick={() => {deleteColumn(groupIndex, tableIndex, columnIndex)}}
                                            disabled={isDeletingColumn}
                                            size="sm"
                                        >
                                            {
                                                isDeletingColumn
                                                ? <Spinner animation="border" size="sm" />
                                                : <i className="flaticon-delete p-0"/>
                                            }
                                        </Button>
                                    </div>
                                : <></>
                            }
                    </div>
                </div>
            )
        );
    }

    const displayError = (message?: string) => {
        return (
            message ?
                <small style={{ color: "#F019A6" }}>*{message}</small>
                : <></>
        );
    }

    const changeTableField = (gIndex: number, tIndex: number, cIndex: number, fIndex: number, impValue: any) => {
        let val: any = value;
        if (val[gIndex]) {
            if (val[gIndex].tables[tIndex]) {
                if (val[gIndex].tables[tIndex][cIndex]) {
                    val[gIndex].tables[tIndex][cIndex][fIndex] = impValue;
                    setValue(val);
                }
            }
        }
        setUpdate();
    }

    const tableFieldType = (type: string, gIndex: number, tIndex: number, cIndex: number, fIndex: number, placeholder?: string, rows?: number) => {
        let condition = 
            permitionsGroup.includes(logUserGroupPermition[gIndex]) ?
                logUserGroupPermition[gIndex] == "Não visualizar" ? true : false
                : 
                false;
        let fieldValue = value[gIndex].tables[tIndex][cIndex][fIndex];
        let required = form[gIndex].tables[tIndex].dataColumns[fIndex].required;
        let err = formTableErrors ? 
            formTableErrors.length ?
                formTableErrors[tIndex][cIndex] ?
                    formTableErrors[tIndex][cIndex][fIndex]
                    : ""
                : ""
            : "";

        requiredTableFields.push({
            cIndex: cIndex,
            fIndex: fIndex,
            value: fieldValue
        });

        switch (type) {
            case "Texto":
                return(
                    <>
                        <Tooltip title={condition ? 'Você não possui permissão para editar' : ''}>
                            <TextField
                                label={placeholder}
                                size="small"
                                margin={"normal"}
                                variant={"outlined"}
                                value={fieldValue}
                                onChange={(e) => changeTableField(gIndex, tIndex, cIndex, fIndex, e.target.value)}
                                style={{width: "100%"}}
                                disabled={condition}
                                required={required}
                                error={err}
                                multiline={rows! > 1}
                                // rows={rows}
                            />
                        </Tooltip>
                        <br/>
                        {displayError(err)}
                    </>
                );
            case "Inteiro":
                return(
                    <>
                        <Tooltip title={condition ? 'Você não possui permissão para editar' : ''}>
                            <TextField
                                size="small"
                                margin={"normal"}
                                variant={"outlined"}
                                type={"number"}
                                label={placeholder}
                                value={fieldValue}
                                onChange={(e) => changeTableField(gIndex, tIndex, cIndex, fIndex, e.target.value)}
                                style={{width: "100%"}}
                                disabled={condition}
                                required={required}
                                error={err}
                            />
                        </Tooltip>
                        <br/>
                        {displayError(err)}
                    </>
                );
            case "Decimal":
                return(
                    <>
                        <Tooltip title={condition ? 'Você não possui permissão para editar' : ''}>
                            <TextField
                                size="small"
                                margin={"normal"}
                                variant={"outlined"}
                                type={"number"}
                                label={placeholder}
                                value={fieldValue}
                                onChange={(e) => changeTableField(gIndex, tIndex, cIndex, fIndex, e.target.value)}
                                style={{width: "100%"}}
                                disabled={condition}
                                required={required}
                                error={err}
                            />
                        </Tooltip>
                        <br/>
                        {displayError(err)}
                    </>
                );
            case "OPCOES":
                let data = form[gIndex].tables[tIndex].dataColumns[fIndex].option;
                let selValue = fieldValue ? fieldValue : 0;
                return(
                    <>
                        <Tooltip title={condition ? 'Você não possui permissão para editar' : ''}>
                            <TextField
                                size="small"
                                margin={"normal"}
                                variant={"outlined"}
                                select
                                label={placeholder}
                                value={selValue}
                                onChange={(e) => changeTableField(gIndex, tIndex, cIndex, fIndex, e.target.value)}
                                style={{width: "100%"}}
                                disabled={condition}
                                required={required}
                                error={err}
                            >
                                <MenuItem value={0} selected>{placeholder} selecione...</MenuItem>
                                {
                                    data.map((d: any, index: number) =>
                                        <MenuItem key={index} value={d}>{d}</MenuItem>
                                    )
                                }
                            </TextField>
                        </Tooltip>
                        <br/>
                        {displayError(err)}
                    </>
                );
            case "Mascara":
                let mask = form[gIndex].tables[tIndex].dataColumns[fIndex].mask;
                let format = mask.replaceAll('0', '#');
                return(
                    <>
                        <Tooltip title={condition ? 'Você não possui permissão para editar' : ''}>
                            <NumberFormat
                                margin="normal"
                                className="mr-3 inputSmaller"
                                customInput={TextField}
                                variant="outlined"
                                format={format}
                                label={placeholder}
                                mask="_"
                                value={fieldValue}
                                onChange={(e) => changeTableField(gIndex, tIndex, cIndex, fIndex, e.target.value)}
                                disabled={condition}
                                required={required}
                                error={err}
                            />
                        </Tooltip>
                        <br/>
                        {displayError(err)}
                    </>
                );
            case "Data":
                return(
                    <>
                        <Tooltip title={condition ? 'Você não possui permissão para editar' : ''}>
                            <TextField
                                size="small"
                                margin={"normal"}
                                variant={"outlined"}
                                type={"date"}
                                label={placeholder}
                                value={fieldValue}
                                onChange={(e) => changeTableField(gIndex, tIndex, cIndex, fIndex, e.target.value)}
                                style={{width: "100%"}}
                                disabled={condition}
                                required={required}
                                error={err}
                            />
                        </Tooltip>
                        <br/>
                        {displayError(err)}
                    </>
                );
            case "Anexo":
                let extensions = form[gIndex].tables[tIndex].dataColumns[fIndex].extension;
                return(
                    <div>
                        <span>{placeholder}</span>
                        <Tooltip title={condition ? 'Você não possui permissão para editar' : ''}>
                            {
                                fieldValue ?
                                    <div style={styles.displayFileField}>
                                        <small style={{marginLeft: 5}}>{fieldValue}</small>
                                        <Button
                                            variant={"danger"}
                                            onClick={() => clearFile(gIndex, fIndex, fieldValue, "table", tIndex, cIndex)}
                                            style={{...styles.tableButton, ...styles.fileDeleteButton}}
                                            disabled={condition}
                                        >
                                            <i className="flaticon-delete p-0"/>
                                        </Button>
                                    </div>
                                    :
                                    <input 
                                        className="form-control"
                                        type="file"
                                        id="formFile"
                                        disabled={condition}
                                        accept={extensions}
                                        required={required}
                                        onChange={(e) => setFile(gIndex, fIndex, e, "table", tIndex, cIndex)}
                                        style={
                                            err.erro ? 
                                                {borderColor: "#e83e8c"}
                                                :
                                                {borderColor: "#BBB"}
                                        }
                                    />
                            }
                        </Tooltip>
                        {displayError(err)}
                    </div>
                );
            case "Caixa_verificacao":
                let v = value[gIndex].tables[tIndex][cIndex][fIndex];
                return(
                    <div className="mt-7">
                        <Tooltip title={condition ? 'Você não possui permissão para editar' : ''}>
                            <Form.Check type="checkbox">
                                <Form.Check.Input 
                                    type="checkbox" 
                                    color={"primary"}
                                    checked={fieldValue} 
                                    onChange={() => changeTableField(gIndex, tIndex, cIndex, fIndex, !v)}
                                    disabled={condition}
                                    required={required}
                                    id={`checkbox-${gIndex}${tIndex}${cIndex}${fIndex}`}
                                />
                                <Form.Check.Label htmlFor={`checkbox-${gIndex}${tIndex}${cIndex}${fIndex}`}>{placeholder}</Form.Check.Label>
                            </Form.Check>
                        </Tooltip>
                    </div>
                );
        }
    }

    const setData = (groupIndex: number, inputIndex: number, val: any) => {
        let valArr: any = value;
        valArr[groupIndex].inputs[inputIndex] = val;
        setValue(valArr);
        setUpdate();
    }

    const fieldType = (type: string, gIndex: number, iIndex: number, label?: string, rows?: number) => {
        let condition = 
            permitionsGroup.includes(logUserGroupPermition[gIndex]) ?
                logUserGroupPermition[gIndex] == "Não visualizar" ? true : false
                : 
                false;
        let fieldValue = value[gIndex].inputs[iIndex];
        let required = form[gIndex].inputs[iIndex].required;
        let err = formErrors ? formErrors[iIndex] : "";

        requiredFields.push({value: fieldValue, required});

        switch (type) {
            case "Texto":
                return(
                    <>
                        <Tooltip title={condition ? 'Você não possui permissão para editar' : ''}>
                            <TextField                        
                                size="small"
                                margin={"normal"}
                                variant={"outlined"}
                                label={label}
                                value={fieldValue}
                                onChange={(e) => setData(gIndex, iIndex, e.target.value)}
                                style={{width: "100%"}}
                                disabled={condition}
                                required={required}
                                error={err}
                                multiline={rows! > 1}
                                // rows={rows}
                            />
                        </Tooltip>
                        {displayError(err)}
                    </>
                );
            case "Inteiro":
                return(
                    <>
                        <Tooltip title={condition ? 'Você não possui permissão para editar' : ''}>
                            <TextField
                                size="small"
                                margin={"normal"}
                                variant={"outlined"}
                                type={"number"}
                                label={label}
                                value={fieldValue}
                                onChange={(e) => setData(gIndex, iIndex, e.target.value)}
                                style={{width: "100%"}}
                                disabled={condition}
                                required={required}
                                error={err}
                            />
                        </Tooltip>
                        {displayError(err)}
                    </>
                );
            case "Decimal":
                return(
                    <>
                        <Tooltip title={condition ? 'Você não possui permissão para editar' : ''}>
                            <TextField
                                size="small"
                                margin={"normal"}
                                variant={"outlined"}
                                type={"number"}
                                label={label}
                                value={fieldValue}
                                onChange={(e) => setData(gIndex, iIndex, e.target.value)}
                                style={{width: "100%"}}
                                disabled={condition}
                                required={required}
                                error={err}
                            />
                        </Tooltip>
                        {displayError(err)}
                    </>
                );
            case "OPCOES":
                let data = form[gIndex].inputs[iIndex].option;
                let selValue = fieldValue ? fieldValue : 0;
                return(
                    <>
                        <Tooltip title={condition ? 'Você não possui permissão para editar' : ''}>
                            <TextField
                                size="small"
                                margin={"normal"}
                                variant={"outlined"}
                                select
                                label={label}
                                value={selValue}
                                onChange={(e) => setData(gIndex, iIndex, e.target.value)}
                                style={{width: "100%"}}
                                disabled={condition}
                                required={required}
                                error={err}
                            >
                                <MenuItem value={0} selected>{label} selecione...</MenuItem>
                                {
                                    data.map((d: any, index: number) =>
                                        <MenuItem key={index} value={d}>{d}</MenuItem>
                                    )
                                }
                            </TextField>
                        </Tooltip>
                        {displayError(err)}
                    </>
                );
            case "Mascara":
                let mask = form[gIndex].inputs[iIndex].mask;
                let format = mask.replaceAll('0', '#');
                return(
                    <>
                        <Tooltip title={condition ? 'Você não possui permissão para editar' : ''}>
                            <NumberFormat
                                margin="normal"
                                className="mr-3 inputSmaller"
                                customInput={TextField}
                                variant="outlined"
                                format={format}
                                label={label}
                                mask="_"
                                value={fieldValue}
                                onChange={(e) => setData(gIndex, iIndex, e.target.value)}
                                disabled={condition}
                                required={required}
                                error={err}
                            />
                        </Tooltip>
                        {displayError(err)}
                    </>
                );
            case "Data":
                return(
                    <>
                        <Tooltip title={condition ? 'Você não possui permissão para editar' : ''}>
                            <TextField
                                size="small"
                                margin={"normal"}
                                variant={"outlined"}
                                type={"date"}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                label={label}
                                value={fieldValue}
                                onChange={(e) => setData(gIndex, iIndex, e.target.value)}
                                style={{width: "100%"}}
                                disabled={condition}
                                required={required}
                                error={err}
                            />
                        </Tooltip>
                        {displayError(err)}
                    </>
                );
            case "Anexo":
                let extensions = form[gIndex].inputs[iIndex].extension;
                return(
                    <div>
                        <span>{label}</span>
                        <Tooltip title={condition ? 'Você não possui permissão para editar' : ''}>
                            {
                                fieldValue ?
                                    <div style={styles.displayFileField}>
                                        <small
                                            style={{ marginLeft: 5, color: "blue", textDecoration: "underline", cursor: "pointer"}}
                                            onClick={() => downloadFile(fieldValue)}
                                        >
                                            {fieldValue}
                                        </small>
                                        <Button
                                            variant={"danger"}
                                            onClick={() => clearFile(gIndex, iIndex, fieldValue)}
                                            style={{...styles.tableButton, ...styles.fileDeleteButton}}
                                            disabled={condition}
                                        >
                                            <i className="flaticon-delete p-0"/>
                                        </Button>
                                    </div>
                                    :
                                    <input 
                                        className="form-control"
                                        type="file"
                                        id="formFile"
                                        disabled={condition}
                                        accept={extensions}
                                        required={required}
                                        onChange={(e) => setFile(gIndex, iIndex, e)}
                                        style={
                                            err ? 
                                                {borderColor: "#e83e8c"}
                                                :
                                                {borderColor: "#BBB"}
                                        }
                                    />
                            }
                        </Tooltip>
                        {displayError(err)}
                    </div>
                );
            case "Caixa_verificacao":
                let v = value[gIndex].inputs[iIndex];
                return(
                    <div className="mt-7">
                        <Tooltip title={condition ? 'Você não possui permissão para editar' : ''}>
                            <Form.Check type="checkbox">
                                <Form.Check.Input 
                                    type="checkbox" 
                                    color={"primary"}
                                    checked={fieldValue} 
                                    onChange={() => setData(gIndex, iIndex, !v)}
                                    disabled={condition}
                                    required={required}
                                    id={`checkbox-${gIndex}${iIndex}`}
                                />
                                <Form.Check.Label htmlFor={`checkbox-${gIndex}${iIndex}`}>{label}</Form.Check.Label>
                            </Form.Check>
                        </Tooltip>
                    </div>
                );
        }
    }

    const displayInputs = (groupIndex: number, inputs: any = []) => {
        return(
            inputs.map((i: any, inputIndex: number) =>{
                let type = form[groupIndex].inputs[inputIndex].type;
                let label = form[groupIndex].inputs[inputIndex].name;
                let rows = form[groupIndex].inputs[inputIndex].rows;
                return(
                    <Grid item xs={12} key={i.id}>
                        { fieldType(type, groupIndex, inputIndex, label, rows) }
                    </Grid>
                );
            })
        );
    }

    const setFile = async(
        groupIndex: number,
        inputIndex: number,
        event: any,
        type: string = "input",
        tableIndex?: number,
        columnIndex?: number
    ) => {
        try {
            let file = event.target;
            if (!file.files || file.files.length == 0) {
                return;
            }
            let val = file.files[0];
            let fd = new FormData();
            fd.append("file", val, val.name);
            let upload = await api.post("/bpm/solicitation/file", fd);
            let fileUpload = upload.data;
            let valArr = value;
            if (type == "input") {
                valArr[groupIndex].inputs[inputIndex] = fileUpload;
            } else {
                if ((tableIndex != null || tableIndex != undefined) && (columnIndex != null || columnIndex != undefined)) {
                    valArr[groupIndex].tables[tableIndex][columnIndex][inputIndex] = fileUpload;
                }
            }

            let obj = {
                solicitationId,
                value: JSON.stringify(valArr),
            }
            let resp = await api.post("/bpm/solicitation/uploadSolicitationFile", obj);
            let respData = resp.data;
            let respValArr: any = JSON.parse(respData.solicitationData);

            setValue(respValArr);
            setUpdate();
        } catch (error) {
            console.log(error);
        }
    }

    const clearFile = async(
        groupIndex: number,
        inputIndex: number,
        filename: string,
        type: string = "input",
        tableIndex?: number,
        columnIndex?: number
    ) => {
        try {
            await api.delete('/file', {data: { filename }})
            let obj = {
                solicitationId,
                type,
                groupIndex,
                tableIndex,
                columnIndex,
                inputIndex,
            }
            let resp = await api.post("/bpm/solicitation/deleteSolicitationFile", obj);
            let respData = resp.data;
            let respValArr: any = JSON.parse(respData.solicitationData);
            setValue(respValArr);
            setUpdate();
        } catch (error) {
            console.log(error);
        }
    }

    const displayFormData = (g: any, groupIndex: number) => {
        let condition =
            permitionsGroup.includes(logUserGroupPermition[groupIndex]) ?
                logUserGroupPermition[groupIndex] == "Não visualizar" ? true : false
                : 
                false;
        
        return (
            <div key={g.id} className="mt-3">
                <h5 className="mb-0" style={{color: condition ? "#ccc" : "#555",}}>{selectedSolicitationGroup.atividade}</h5>
                <div style={{...styles.groupDivStyle}}>
                    <Grid container spacing={3}>
                        { displayInputs(groupIndex, g.inputs) }
                    </Grid>
                    <Grid container spacing={3}>
                        { displayTables(groupIndex, g.tables, condition) }
                    </Grid>
                </div>
            </div>
        );
    }

    const displayForm = () => {
        return(
            form.length ?
                <>
                    {
                        form.map((g: any, groupIndex: number) => {
                            if(stagePermitions[groupIndex] === 'Editar') {
                                return displayFormData(g, groupIndex);
                            } else {
                                return;
                            }

                            if (!Number(solicitationId)) {
                                return displayFormData(g, groupIndex);
                            } else {
                                if (
                                    (supervisors.includes(user.id) || permitionsGroup.includes(logUserGroupPermition[groupIndex])) 
                                    && logUserGroupPermition[groupIndex] != "Não Exibir"
                                ) {
                                    return displayFormData(g, groupIndex);
                                } else {
                                    return (<></>);
                                }
                            }
                        })
                    }
                </>
                : <></>
        );
    }

    const fetchProcesses = async () => {
        try {
            const results = await api.get('/bpm/solicitacao-processo-formulario/autocomplete');
            setProcesses(results.data);
        } catch (error) {
            console.log(error);
            setIsLoadingSolicitationForm(false);
        }
    }

    useEffect(() => {
        fetchProcesses();
    }, []);

    useEffect(() => {
        handleLoadData();
    }, [rowsPerPageActivity, pagesActivity, triggerLoad, pageChanged]);

    const handleLoadData = useCallback(async () => {
        setIsLoading(true);
        await loadData({ rowsPerPage: rowsPerPageActivity, currentPage: pagesActivity, sortDirection: sortDirectionActivity, sortReference: sortReferenceActivity });
        setIsLoading(false);

        if (pageChanged) {
            setPageChanged(false);
        }

        if (triggerLoad && setTriggerLoad) {
            setPagesActivity(0);
            setTriggerLoad(false);
        }
    }, [rowsPerPageActivity, pagesActivity, triggerLoad, pageChanged, sortDirectionActivity, sortReferenceActivity]);

    const loadData = useCallback(async ({
        rowsPerPage,
        currentPage,
        sortDirection,
        sortReference,
    }: LoadDataParams) => {
        setIsLoadingData(true);
        const { data } = await api.get<{rows: ActivityProcessBpm[], count: number}>(`/bpm/activities`, {
            params: {
                skip: rowsPerPage * currentPage,
                take: rowsPerPage,
                filters: filtersRef.current ? JSON.stringify(filtersRef.current) : undefined,
                sortReference,
                sortDirection,
            }
        });

        const {rows, count} = data;

        setActivitiesCount(count);
        setActivities(rows);
        setIsLoadingData(false);
    }, []);

    const createSolicitation = async (duplicateName?: string, duplicateProcessId?: number) => {
        setIsSubmit(true);

        try {
            let raw = {
                solicitationName: duplicateName ?? solicitationName,
                processId: duplicateProcessId ?? solicitationProcessId,
            }

            await api.post('/bpm/solicitation/NewSolicitation', raw);

            setIsSubmit(false);
            setShowNewSolicitationModal(false);
            setMsgModal("Solicitação criada com sucesso!");
            setShowModalSuccess(true);
        } catch (error) {
            setIsSubmit(false);
            setShowNewSolicitationModal(false);
            setShowModalError(true);
            setMsgError("Erro ao criar solicitação!");
        }
    }

    function closeModalSuccess() {
        setShowModalSuccess(false);
        handleLoadData();
    }

    function inputsVerify() {
        let fErrors = formErrors;
        let ftErrors = formTableErrors;
        let status = 1;
        let formLen = form.length;
        for (let i = 0; i < formLen; i++) {
            if (form[i].activityId == currentStage) {
                let len = form[i].inputs.length;
                for (let j = 0; j < len; j++) {
                    let checkReq = form[i].inputs[j].required;
                    let checkVal = value[i].inputs[j];
                    if (checkReq && !checkVal) {
                        status = 0;
                        fErrors.push("Campo obrigatório!")
                    } else {
                        fErrors.push(null);
                    }
                }

                let tLen = form[i].tables.length;
                for (let t = 0; t < tLen; t++) {
                    ftErrors.push([]);
                    let tValLen = value[i].tables[t].length;
                    for (let c = 0; c < tValLen; c++) {
                        ftErrors[t].push([]);
                        let colLen = value[i].tables[t][c].length;
                        for (let f = 0; f < colLen; f++) {
                            let req = form[i].tables[t].dataColumns[f].required;
                            let val = value[i].tables[t][c][f];
                            if (req && !val) {
                                status = 0;
                                ftErrors[t][c].push("Campo obrigatório!")
                            } else {
                                ftErrors[t][c].push(null);
                            }
                        }
                    }
                }
            }
        }
        if (!status){
            setFormErrors(fErrors);
            setFormTableErrors(ftErrors);
            setMsgError("Preencha os campos obrigatórios!");
            setIsSubmitSolicitationForm(false);
            setShowModalError(true);
            status = 0;
            return false;
        } 
        return true;
    }

    const onSubmit = async () => {
        setIsSubmitSolicitationForm(true);

        if (!inputsVerify()) return;

        try {
            let obj;

            if (selectedSolicitationGroup.tipo === "Exclusivo") {
                obj = {
                    solicitationId,
                    processId,
                    form,
                    value,
                    advanceToNextActivity,
                    selectedNextStage,
                    checkedActivity
                }
            } else {
                obj = {
                    solicitationId,
                    processId,
                    form,
                    value,
                    advanceToNextActivity,
                    checkedActivity
                }
            }

            await api.post("/bpm/solicitation/save", obj);

            setMsgModal("Formulário respondido com sucesso!");
            setShowViewSolicitationModal(false);
            setShowModalSuccess(true);
            setFormErrors([]);
            setFormTableErrors([]);

            if (setUpdateList) {
                setUpdateList(true);
            }
        } catch (error) {
            setMsgError("Erro ao responder formulário!");
            setShowModalError(true);
            console.log(error);
        }

        setIsSubmitSolicitationForm(false);
    }

    function verifyAdvanceButton() {
        if (selectedSolicitationGroup.aprovacaoSupervisor) {
            if (responsible.length === 0) {
                showButton = supervisors.includes(user.id);
            } else {
                showButton = supervisors.includes(user.id) || responsible.includes(user.id);
            }
        } else {
            if (responsible.length > 0) {
                showButton = responsible.includes(user.id);
            } else {
                showButton = true;
            }
        }

        return showButton;
    }

    function renderChangedInputsValues() {
        const changedInputs = JSON.parse(selectedHistory.changedValues).changedInputs;

        return changedInputs.map((changedInput: any, index: number) => (
            changedInput.value
            ?
                <div key={index} className="card p-4">
                    <h5>{selectedHistory.solicitationName}</h5>
                    <h6>Alterou o campo "{changedInput.input}" para "{changedInput.value}"</h6>
                    <p>{selectedHistory.end_date}</p>
                </div>
            : <></>
        ));
    }

    function renderChangedTableInputsValues() {
        const changedTableInputs = JSON.parse(selectedHistory.changedValues).changedTableInputs;

        return changedTableInputs.map((changedTableInput: any, index: number) => (
            changedTableInput.value
            ?
                <div key={index} className="card p-4">
                    <h5>{selectedHistory.solicitationName}</h5>
                    <h6>Alterou o campo de tabela "{changedTableInput.tableInput}" para "{changedTableInput.value}"</h6>
                    <p>{selectedHistory.end_date}</p>
                </div>
            : <></>
        ))
    }

    function setProcessActivityDate(daysToAdd: number) {
        const today = new Date();
        today.setDate(today.getDate() + daysToAdd);

        const formattedDate = getDate({ initialDate: today }).dateStr;

        return formattedDate;
    }

    const checkActivity = (e: ChangeEvent<HTMLInputElement>, index: number) => {
        let cSteps = checkedSteps;
        let dSteps = disabledSteps;
        let checked;

        if (e.target.checked) {
            setCheckedActivity(e.target.value);
            cSteps[index] = true;
            checked = true;

            for (let i = 0; i < index; i++) {
                cSteps[i] = true;
                dSteps[i] = true;
            }
        } else {
            setCheckedActivity("");
            cSteps[index] = false;
            checked = false;

            for (let i = 1; i < index; i++) {
                cSteps[i] = false;
                dSteps[i] = false;
            }
        }

        setDisabledSteps(dSteps);
        setCheckedSteps(cSteps);
    }

    return (
        <>
            <ModalError
                msgError={msgError}
                showModalError={showModalError}
                setShowModalError={setShowModalError}
            />

            <ModalSuccess
                msgModal={msgModal}
                showModal={showModalSuccess}
                setShowModal={setShowModalSuccess}
                onClose={closeModalSuccess}
            />

            <Modal
                size="lg"
                show={showViewHistoricModal}
                onHide={() => setShowViewHistoricModal(false)}
                scrollable
            >
                <Modal.Header>
                    <Modal.Title>
                        Ação do usuário
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className="d-flex flex-column" style={{ gap: "0.75rem" }}>
                    <h4>{selectedHistory.user_name}</h4>
                    {
                        selectedHistory.changedValues
                        &&
                            (
                                <>
                                    {renderChangedInputsValues()}
                                    {renderChangedTableInputsValues()}
                                </>
                            )
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowViewHistoricModal(false)}>Fechar</Button>
                </Modal.Footer>
            </Modal>

            <Modal
                size="lg"
                show={showViewSolicitationModal}
                backdrop="static"
                onHide={() => setShowViewSolicitationModal(false)}
                scrollable
            >
                <Modal.Header>
                    <Modal.Title>
                        {
                            isLoadingSolicitationForm
                            ? <>Carregando...</>
                            : <>{name}</>
                        }
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {
                        isLoadingSolicitationForm
                        ?
                            <div className="d-flex justify-content-center">
                                <Spinner animation="border" />
                            </div>
                        :
                            <>
                                <Tabs
                                    activeKey={activeTab}
                                    onSelect={(tab: string) => setActiveTab(tab)}
                                    id="solicitation-tabs"
                                >
                                    <Tab eventKey="form" title="Formulário">
                                        { displayForm() }
                                        {
                                            statusSolicitation === "completed"
                                            ?
                                                <div className="mt-6 text-center font-size-lg font-weight-bold">
                                                    Solicitação finalizada!
                                                </div>
                                            :
                                                <div className="mt-3 d-flex justify-content-between">
                                                    {
                                                        destinationOptions.length
                                                        ?
                                                            <FormControl className='mt-8'>
                                                                <FormLabel id="destinations-radio-buttons-group-label">Próxima atividade:</FormLabel>
                                                                    <RadioGroup
                                                                        className="d-flex flex-row"
                                                                        aria-labelledby="destinations-radio-buttons-group-label"
                                                                        name="destinations-radio-group"
                                                                    >
                                                                        {
                                                                            destinationOptions.map((destination: any, index: number) => (
                                                                                <FormControlLabel key={index} value={destination.value} control={<Radio color="primary" onChange={(e) => setSelectedNextStage(e.target.value)} />} label={destination.name} />
                                                                            ))
                                                                        }
                                                                    </RadioGroup>
                                                            </FormControl>
                                                        : <></>
                                                    }
                                                    {verifyAdvanceButton() &&
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={advanceToNextActivity}
                                                                    onChange={(e) => setAdvanceToNextActivity(e.target.checked)}
                                                                    color="primary"
                                                                    disabled={selectedSolicitationGroup.tipo === "Exclusivo"}
                                                                />
                                                            }
                                                            label="Ir para a próxima atividade"
                                                        />
                                                    }
                                                </div>
                                        }
                                    </Tab>

                                    {
                                        selectedSolicitationGroup.anexos && selectedSolicitationGroup.anexos.length > 0 &&
                                        (
                                            <Tab eventKey="attachments_activity" title="Anexos da atividade">
                                                <div className="d-flex flex-column mt-3" style={{ gap: "1rem" }}>
                                                    {
                                                        selectedSolicitationGroup.anexos.map((anexo: FileItem, index: number) => (
                                                            <div key={index} className="card px-4 py-3 d-flex flex-row justify-content-between align-items-center">
                                                                <span>{anexo.file}</span>
                                                                <Button onClick={() => downloadFile(anexo.file)} variant="primary" size="sm">
                                                                    <i className="flaticon-download p-0"></i>
                                                                </Button>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </Tab>
                                        )
                                    }

                                    <Tab eventKey="steps" title="Etapas">
                                        <Grid container className="mt-3">
                                            <Grid item xs={2} className="p-3">
                                                <h6>Selecione</h6>
                                            </Grid>
                                            <Grid item xs={4} className="p-3">
                                                <h6>Atividade</h6>
                                            </Grid>
                                            <Grid item xs={3} className="p-3">
                                                <h6>Responsável</h6>
                                            </Grid>
                                            <Grid item xs={3} className="p-3">
                                                <h6>Prazo</h6>
                                            </Grid>
                                        </Grid>

                                        <div className="d-flex flex-column" style={{ rowGap: "0.5rem" }}>
                                            {
                                                solicitationProcess.length
                                                &&
                                                solicitationProcess.toReversed().map((process: any, index: number) => (
                                                    <Grid key={process.id} container className={"card flex-row " + (process.tipo === "Exclusivo" ? "d-none" : "")} alignItems="center">
                                                        <Grid item xs={2} className="p-3">
                                                            <Checkbox
                                                                disabled={disabledSteps[index]}
                                                                checked={checkedSteps[index]}
                                                                onChange={(e) => checkActivity(e, index)}
                                                                value={process.id}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4} className="p-3">
                                                            {process.atividade}
                                                        </Grid>
                                                        <Grid item xs={3} className="p-3">
                                                            {
                                                                process.responsaveis.length
                                                                ?
                                                                `${process.responsaveis[0].firstname} ${process.responsaveis[0].lastname}`
                                                                :
                                                                "Sem responsável"}
                                                        </Grid>
                                                        <Grid item xs={3} className="p-3">
                                                            {setProcessActivityDate(process.prazo)}
                                                        </Grid>
                                                    </Grid>
                                                ))
                                            }
                                        </div>
                                    </Tab>

                                    <Tab eventKey="history" title="Histórico">
                                        {
                                            solicitationHistory.length
                                            ?
                                                <SolicitationHistory
                                                    historyData={solicitationHistory}
                                                    solicitationCreatedAt={solicitationCreatedAt}
                                                    setShowViewHistoricModal={setShowViewHistoricModal}
                                                    setSelectedHistory={setSelectedHistory}
                                                />
                                            :
                                                <div className="mt-6 text-center font-size-lg font-weight-bold">
                                                    Não há histórico de solicitação!
                                                </div>
                                        }
                                    </Tab>

                                    <Tab eventKey="diagram" title="Diagrama">
                                        <BpmnWithFormViewer
                                            tableRows={solicitationProcess}
                                            setTableRows={setSolicitationProcess}
                                            processActivities={processActivities}
                                            createWithForm={false}
                                            displayOnly={true}
                                            selectedShape={selectedShape}
                                            setSelectedShape={setSelectedShape}
                                        />
                                    </Tab>

                                    <Tab eventKey="attachments" title="Anexos">
                                        <div className="d-flex flex-column mt-3" style={{ gap: "1rem" }}>
                                            {
                                                solicitationAttachments && solicitationAttachments.length > 0
                                                ?
                                                    (
                                                        solicitationAttachments.map((anexo: FileItem, index: number) => (
                                                            <div key={index} className="card px-4 py-3 d-flex flex-row justify-content-between align-items-center">
                                                                <span>{anexo.file}</span>
                                                                <Button onClick={() => downloadFile(anexo.file)} variant="primary" size="sm">
                                                                    <i className="flaticon-download p-0"></i>
                                                                </Button>
                                                            </div>
                                                        ))
                                                    )
                                                :
                                                    <div className="mt-6 text-center font-size-lg font-weight-bold">
                                                        Não há anexos no processo!
                                                    </div>
                                            }
                                        </div>
                                    </Tab>
                                </Tabs>
                            </>
                    }
                </Modal.Body>
                <Modal.Footer>
                    {
                        statusSolicitation === "completed"
                        ? <></>
                        :
                            <Button variant="primary" onClick={onSubmit} disabled={isSubmitSolicitationForm}>
                                {isSubmitSolicitationForm ? <>
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />

                                    <span className="ml-2">
                                        Aguarde...
                                    </span>
                                </> : <>
                                    <span>
                                        Salvar
                                    </span>
                                </>}
                            </Button>
                    }
                    <Button variant="secondary" onClick={closeSolicitationModal}>Fechar</Button>
                </Modal.Footer>
            </Modal>

            <Modal
                show={showNewSolicitationModal}
                onHide={() => setShowNewSolicitationModal(false)}
            >
                <Modal.Header>
                    <Modal.Title>Nova solicitação</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Grid container>
                        <Grid item lg={12}>
                            <TextField
                                label="Nome da solicitação"
                                size="small"
                                margin="normal"
                                variant="outlined"
                                type="text"
                                value={solicitationName}
                                onChange={(e) => setSolicitationName(e.target.value)}
                            />
                        </Grid>
                        <Grid item lg={12}>
                            <TextField
                                select
                                label="Processo"
                                size="small"
                                margin="normal"
                                variant="outlined"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={solicitationProcessId}
                                onChange={(e) => setSolicitationProcessId(e.target.value)}
                            >
                                {
                                    processes
                                    ? processes.map((process) => 
                                        <MenuItem key={process.id} value={process.id}>
                                            {process.name}
                                        </MenuItem>
                                    )
                                    : (
                                        "Não há processos!"
                                    )
                                }
                            </TextField>
                        </Grid>
                    </Grid>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => createSolicitation()} disabled={isSubmit}>
                        {isSubmit ? <>
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />

                            <span className="ml-2">
                                Aguarde...
                            </span>
                        </> : <>
                            <span>
                                Salvar
                            </span>
                        </>}
                    </Button>
                    <Button variant="secondary" onClick={() => setShowNewSolicitationModal(false)}>Fechar</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

const styles = {
    groupDivStyle: {
        width: "100%",
    },
    tableButton: {
        width: 45,
        height: 45,
    },
    displayFileField: {
        width: "100%",
        height: 54,
        border: "1px solid #BBB",
        borderRadius: 4,
        lineHeight: "50px",
    },
    fileDeleteButton: {
        float:"right" as "right",
        marginRight: 5,
        marginTop:3,
    }
}

export default SolicitationFormModal;